import { ReactNode } from 'react';
import { StyledPageHeader } from './PageHeader.styled';

type PageHeaderProps = {
  title: string;
  description?: string;
  rightSection?: ReactNode;
};

const PageHeader = ({ title, description, rightSection }: PageHeaderProps) => {
  return (
    <StyledPageHeader $description={description}>
      <div>
        {title && <h2 className="title">{title}</h2>}
        {description && <p className="description">{description}</p>}
      </div>
      {rightSection}
    </StyledPageHeader>
  );
};

export default PageHeader;
