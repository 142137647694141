import { Button, Modal } from '@mantine/core';
import emailSvg from '../../assets/email.svg';
import classes from './VerifyEmailModal.module.css';

type VerifyEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const VerifyEmailModal = ({ isOpen, onClose }: VerifyEmailModalProps) => {
  return (
    <Modal opened={isOpen} onClose={onClose} title="Verify your email">
      <div className="verify-container">
        <p className="verify-message">
          An email verification link has been sent to your email address. Follow the link to verify
          your email and activate all features.
        </p>
        <div className={classes.imageContainer}>
          <img src={emailSvg} alt="Email/letter icon" className={classes.image} />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button variant="outline" onClick={onClose}>
          Continue to app
        </Button>
      </div>
    </Modal>
  );
};
