import { ReactNode, forwardRef, useState } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

type TextInputWithCheckmarkProps = {
  /**
   * Whether the input is considered valid. The checkmark will be shown on blur if `isValid`
   * is true.
   */
  isValid: boolean;
  shouldValidateOnChange?: boolean;
} & TextInputProps;

/**
 * A wrapper around Mantine's `TextInput` component that displays a checkmark icon onBlur when the
 * input is valid.
 */
export const TextInputWithCheckmark = forwardRef<HTMLInputElement, TextInputWithCheckmarkProps>(
  ({ isValid, shouldValidateOnChange = false, ...props }, ref) => {
    const [isBlurred, setIsBlurred] = useState(false);

    const getRightSectionContent = (): ReactNode | null => {
      if (shouldValidateOnChange) {
        return isValid ? <IconCheck width={16} color="#529f95" /> : null;
      }
      return isValid && isBlurred ? <IconCheck width={16} color="#529f95" /> : null;
    };

    return (
      <TextInput
        {...props}
        // Validate the input onBlur only (i.e. once the user has moved on to the next field).
        onBlur={(event) => {
          props.onBlur?.(event);
          setIsBlurred(true);
        }}
        // If the user returns to the field, remove validation and reset the 'blur' state. This avoids
        // instant validation when returning to a field.
        onFocus={(event) => {
          props.onFocus?.(event);
          setIsBlurred(false);
        }}
        rightSection={getRightSectionContent()}
        ref={ref}
      />
    );
  },
);

TextInputWithCheckmark.displayName = 'TextInputWithCheckmark';
