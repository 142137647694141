/**
 * A reusable validation function for required inputs
 */
export const validateRequiredInput = (value: string, message?: string): string | null => {
  const finalMessage = message ?? 'This field cannot be left blank';
  return value.trim().length === 0 ? finalMessage : null;
};

/**
 * Validates a 'quantity' value ensuring it is a number greater than zero, without leading zeroes.
 * An optional maximum allowed value can be specified.
 *
 * @param value - The 'quantity' value to validate.
 * @param maxAllowedValue - Optional maximum allowed value for the quantity.
 * @returns Returns null if validation passes, or an error message string if validation fails.
 */
export const isQuantityValid = (value: string, maxAllowedValue?: number): string | null => {
  if (value.trim().length === 0) {
    return 'This field cannot be left blank';
  } else if (!/^[1-9][0-9]*$/.test(value.trim())) {
    return 'Please enter a quantity of 1 or more (with no leading zeroes)';
  } else if (maxAllowedValue !== undefined && parseInt(value, 10) > maxAllowedValue) {
    return `Quantity cannot exceed ${maxAllowedValue}`;
  }
  return null;
};

/**
 * Validates a 'repeats' value ensuring it is a non-negative number without leading zeroes.
 * An optional maximum allowed value can be specified.
 *
 * @param value - The 'repeats' value to validate.
 * @param maxAllowedValue - Optional maximum allowed value for the repeats.
 * @returns Returns null if validation passes, or an error message string if validation fails.
 */
export const isRepeatsValid = (value: string, maxAllowedValue?: number): string | null => {
  if (value.trim().length === 0) {
    return 'This field cannot be left blank';
  } else if (!/^(0|[1-9][0-9]*)$/.test(value.trim())) {
    return 'Please enter a valid number (no leading zeroes)';
  } else if (maxAllowedValue !== undefined && parseInt(value, 10) > maxAllowedValue) {
    return `Repeats cannot exceed ${maxAllowedValue}`;
  }
  return null;
};

/**
 * Allows the user to toggle checkboxes on 'Enter' key press
 */
export const handleCheckboxEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    event.currentTarget.click();
  }
};
