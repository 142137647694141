import { styled } from 'styled-components';

const StyledErrorView = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  width: 100%;
  text-align: center;
`;

export const FallbackErrorBoundary = () => (
  <StyledErrorView>
    <h1>Oops. Something went wrong.</h1>
    <p>
      Try returning to the <a href="/">home page</a>
    </p>
  </StyledErrorView>
);
