import { useRef, useState, useEffect } from 'react';
import { StyledAddressAutocomplete } from './AddressAutocomplete.styled';
import { Address } from '../../types/firestore';
import { UseFormReturnType } from '@mantine/form';
import classes from '../RxForm/RxForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';
import { TextInput, Button } from '@mantine/core';

type AddressAutocompleteProps<T> = {
  googleLoaded: boolean;
  form: UseFormReturnType<T>;
  /**
   * The field path to the address section of the form. Required if the address fields are nested.
   */
  fieldPath?: string;
  /**
   * Callback to handle a change in address values after selecting an autocomplete option.
   * MUST BE WRAPPED IN CALLBACK!
   */
  handleAddressChange: (address: Address) => void;
};

const formatFieldName = (fieldName: string, fieldPath?: string) => {
  if (!fieldPath) {
    return fieldName;
  }
  return `${fieldPath}.${fieldName}`;
};

const getAddressFromPlaceResult = (placeResult: google.maps.places.PlaceResult): Address => {
  const address: Address = {
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
  };

  placeResult['address_components']?.forEach((component) => {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        address.streetAddress = component.long_name;
        break;
      }
      case 'route': {
        address.streetAddress = `${address.streetAddress} ${component.long_name}`;
        break;
      }
      case 'postal_code': {
        address.postcode = component.long_name;
        break;
      }
      case 'locality': {
        address.suburb = component.long_name;
        break;
      }
      case 'administrative_area_level_1': {
        address.state = component.short_name;
        break;
      }
      default: {
        break;
      }
    }
  });

  return address;
};

const AddressAutocomplete = <T,>({
  googleLoaded,
  form,
  fieldPath,
  handleAddressChange,
}: AddressAutocompleteProps<T>) => {
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const autocompleteInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete;
    const input = autocompleteInputRef.current;

    if (!input) {
      return;
    }
    // Prevent the default action of submitting the form when enter key is pressed, but ONLY under
    // the condition that the user is selecting an autocomplete suggestion.
    input.addEventListener('keydown', (event) => {
      const selected = document.querySelector('.pac-item-selected');
      if (event.key === 'Enter' && selected) {
        event.preventDefault();
      }
    });

    const onPlaceChanged = () => {
      // Gets the information from Google about the place that was selected
      const place = autocomplete.getPlace();

      // The `geometry` property will only be available if an autocomplete suggestion is actually
      // selected. It won't appear if for example the user hits enter without selecting an option.
      if (place.geometry) {
        const address = getAddressFromPlaceResult(place);
        setIsFormExpanded(true);
        handleAddressChange(address);
      }
    };

    // Once the google API is loaded, we can add the autocomplete functionality to the target input
    if (googleLoaded) {
      autocomplete = new google.maps.places.Autocomplete(input, {
        // Australian addresses only
        componentRestrictions: { country: ['AU'] },
        // Restrict to basic data only to avoid excess API usage and charges
        fields: ['address_components', 'name', 'formatted_address', 'adr_address', 'geometry'],
      });
      autocomplete.addListener('place_changed', onPlaceChanged);
    }

    return () => {
      if (typeof google !== 'undefined') {
        // NOTE: `maps` and/or `event` can be undefined here
        google.maps?.event?.clearInstanceListeners(input);
        document.querySelectorAll('.pac-container').forEach((container) => container.remove());
      }
    };
  }, [googleLoaded, handleAddressChange, autocompleteInputRef]);

  return (
    <StyledAddressAutocomplete
      className={`${
        isFormExpanded ? 'AddressAutocomplete expanded' : 'AddressAutocomplete collapsed'
      }`}
    >
      <legend className="visually-hidden">Patient Address</legend>
      <TextInputWithCheckmark
        classNames={{ root: classes.addressAutocompleteInput }}
        label="Street address"
        placeholder="Enter a location"
        ref={autocompleteInputRef}
        {...form.getInputProps(formatFieldName('streetAddress', fieldPath))}
        isValid={form.isValid(formatFieldName('streetAddress', fieldPath))}
      />
      <Button
        size="compact-xs"
        variant="transparent"
        className="expand-btn"
        onClick={() => setIsFormExpanded((prevState) => !prevState)}
      >
        {isFormExpanded ? 'Hide extra fields' : 'Enter manually'}
      </Button>

      <div className={`address-collapse ${isFormExpanded ? 'show' : 'hide'}`}>
        <TextInput
          classNames={{ root: classes.formInput }}
          label="Apartment, unit, shop, suite, or floor #"
          {...form.getInputProps(formatFieldName('subpremise', fieldPath))}
        />
        <TextInputWithCheckmark
          classNames={{ root: classes.formInput }}
          label="Suburb"
          {...form.getInputProps(formatFieldName('suburb', fieldPath))}
          isValid={form.isValid(formatFieldName('suburb', fieldPath))}
          shouldValidateOnChange
        />
        <TextInputWithCheckmark
          classNames={{ root: classes.formInput }}
          label="State"
          {...form.getInputProps(formatFieldName('state', fieldPath))}
          isValid={form.isValid(formatFieldName('state', fieldPath))}
          shouldValidateOnChange
        />
        <TextInputWithCheckmark
          classNames={{ root: classes.formInput }}
          label="Postcode"
          {...form.getInputProps(formatFieldName('postcode', fieldPath))}
          isValid={form.isValid(formatFieldName('postcode', fieldPath))}
          shouldValidateOnChange
          maxLength={4}
        />
      </div>
    </StyledAddressAutocomplete>
  );
};

export default AddressAutocomplete;
