import { TextInput, Checkbox, Textarea } from '@mantine/core';
import { PrescriptionFormValues } from '../../types/firestore';
import { AlertState } from '../utils/Alert/Alert';
import { UseFormReturnType } from '@mantine/form';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import classes from '../RxForm/RxForm.module.css';

type AuthorityDetailsProps = {
  drugAlerts: AlertState;
  miscAlerts: AlertState;
  numbersLoading: boolean;
  form: UseFormReturnType<PrescriptionFormValues>;
};

// Removing the individual form fields from the parent component, but still utilising parent state. This is mainly to help RxForm component readability
const AuthorityDetails = ({
  drugAlerts,
  miscAlerts,
  numbersLoading,
  form,
}: AuthorityDetailsProps) => {
  const { drugData, miscData } = form.values;
  return (
    <>
      <Checkbox
        classNames={{ root: classes.authorityRequiredInput }}
        label="Authority required"
        onKeyDown={handleCheckboxEnterPress}
        {...form.getInputProps('drugData.authRequired', { type: 'checkbox' })}
        description={drugAlerts.authRequired?.message}
      />
      {drugData.authRequired && drugData.pbsRx && (
        <>
          <div className="numbers" data-testid="numbers">
            {/* drugData.authRequired should be auto-selected once PBS integration is complete, but should also have an option to set manually */}
            {drugData.authRequired && (
              <div className="authRxNo">
                Authority script number: {numbersLoading ? 'Loading...' : miscData.authRxNumber}
              </div>
            )}
          </div>

          <TextInput
            classNames={{ root: classes.formInput }}
            label="Authority code (where applicable)"
            {...form.getInputProps('miscData.authCode')}
            // FIXME: pass in an actual component to description, which should be the 'alert' rather
            // than an error
            description={miscAlerts.authCode?.message}
            inputWrapperOrder={['label', 'input', 'description', 'error']}
          />

          <div className="retention">
            <Textarea
              classNames={{ root: classes.textarea, input: classes.textareaWrapper }}
              label="Clinical justification for use of item"
              cols={30}
              rows={3}
              {...form.getInputProps('miscData.justification')}
            />
            <TextInput
              classNames={{ root: classes.formInput }}
              type="number"
              label="Patient's age if under 18"
              {...form.getInputProps('miscData.age')}
            />
            <Checkbox
              classNames={{ root: classes.formInput }}
              label="Patient has received authority for this medicine before"
              onKeyDown={handleCheckboxEnterPress}
              {...form.getInputProps('miscData.prevAuth', { type: 'checkbox' })}
            />
          </div>
        </>
      )}
      <TextInput
        classNames={{ root: classes.formInput }}
        label="Date"
        type="date"
        {...form.getInputProps('miscData.date')}
      />
    </>
  );
};

export default AuthorityDetails;
