import { useSignup } from '../../hooks/useSignup';
import { Link } from 'react-router-dom';
import { StyledSignup } from './Signup.styled';
import { Helmet } from 'react-helmet-async';
import { Button, TextInput, Alert, PasswordInput, Anchor } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateRequiredInput } from '../../utils/formUtils';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from '../../styles/Alert.module.css';

type SignupProps = {
  setFirstSignIn: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  email: string;
  password: string;
  fullName: string;
  prescriberNumber: string;
  qualifications: string;
};

const Signup = ({ setFirstSignIn }: SignupProps) => {
  const { error, isPending, signup, setError } = useSignup();

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      fullName: '',
      prescriberNumber: '',
      qualifications: '',
    },

    validate: {
      email: (value) => validateRequiredInput(value, 'Please enter an email address'),
      password: (value) => validateRequiredInput(value, 'Please enter a password'),
      fullName: (value) => validateRequiredInput(value),
      prescriberNumber: (value) =>
        !/^[0-9]{7}$/.test(value.trim()) ? 'Prescriber number must be a seven digit number' : null,
    },
  });

  return (
    <>
      <Helmet>
        <title>Sign up · OptomRx</title>
        <meta
          name="description"
          content="A new way for optometrists to professionally create and manage therapeutic prescriptions online."
        />
        <link rel="canonical" href="/signup" />
      </Helmet>
      <StyledSignup className="Signup">
        <div className="Signup__container">
          <h2 className="Signup__title">Sign up</h2>

          <form
            className="Signup__form"
            noValidate
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              signup(values, setFirstSignIn);
            })}
          >
            <TextInput
              label="Email"
              autoComplete="username"
              className="auth-field form-field"
              type="email"
              {...form.getInputProps('email')}
              onChange={(event) => {
                // Must call the original onChange handler from form.getInputProps
                form.getInputProps('email').onChange(event);
                // Custom onChange logic
                setError('');
              }}
            />
            <PasswordInput
              label="Password"
              autoComplete="new-password"
              className="auth-field form-field"
              {...form.getInputProps('password')}
              onChange={(event) => {
                // Must call the original onChange handler from form.getInputProps
                form.getInputProps('password').onChange(event);
                // Custom onChange logic
                setError('');
              }}
              size="md"
              description="Password must contain at least 6 characters."
              inputWrapperOrder={['label', 'input', 'description', 'error']}
            />

            <TextInput
              label="Full name"
              className="auth-field form-field"
              {...form.getInputProps('fullName')}
            />
            <TextInput
              label="Prescriber number"
              className="auth-field form-field"
              {...form.getInputProps('prescriberNumber')}
              maxLength={7}
            />
            <TextInput
              className="auth-field form-field qualifications-form-field"
              label="Abbreviated qualifications (optional)"
              {...form.getInputProps('qualifications')}
            />

            {error ? (
              <Alert
                variant="light"
                icon={<IconInfoCircle />}
                color="red"
                className={classes.errorAlert}
              >
                {error}
              </Alert>
            ) : null}

            <Button
              className="Signup__btn"
              type="submit"
              variant="primary"
              loading={isPending}
              size="md"
            >
              Sign up
            </Button>
          </form>
          <p className="disclaimer">
            By registering, you agree to OptomRx&apos;s{' '}
            <Anchor className="disclaimer" component={Link} to="/terms-of-service" target="_blank">
              Terms of Service
            </Anchor>{' '}
            and{' '}
            <Anchor className="disclaimer" component={Link} to="/privacy-policy" target="_blank">
              Privacy Policy
            </Anchor>
          </p>

          <div className="login-option">
            <div className="login-group">
              <span className="login-msg">Already have an account?</span>
              <Anchor component={Link} to="/login" className="login-link">
                Log in.
              </Anchor>
            </div>
          </div>
        </div>
      </StyledSignup>
    </>
  );
};

export default Signup;
