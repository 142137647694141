import { StyledAbout } from './About.styled';
import { Helmet } from 'react-helmet-async';
import { LandingPageContainer } from '../utils/LandingPageContainer/LandingPageContainer';
import { Anchor } from '@mantine/core';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About · OptomRx</title>
        <meta
          name="description"
          content="OptomRx, created in 2021, is a new web app for Australian optometrists to write therapeutic prescriptions online. Learn more about us here."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <LandingPageContainer>
        <StyledAbout>
          <h2 className="About__heading">Learn more about OptomRx</h2>

          <section className="About__section">
            <h3>What exactly is OptomRx</h3>
            <p>
              OptomRx is a web application software designed for Australian Optometrists, by
              Australian Optometrists.
            </p>
            <p>
              The software is designed to help Australian Optometrists write therapeutic
              prescriptions. The ultimate goal of OptomRx is to streamline the prescription writing
              process as much as possible, avoiding awkward time wasting through traditional
              handwritten prescriptions or clunky dated software.
            </p>
            <p>
              To achieve this, OptomRx provides a number of features that not only speed up
              prescription writing, but help improve accuracy and professionalism of scripts. Right
              now, you are viewing the OptomRx public website. The OptomRx web application for
              prescribers is accessible to registered users, and can be accessed by{' '}
              <a href="/signup">creating an account</a> and logging in.
            </p>
          </section>

          <section className="About__section">
            <h3>What separates us from other prescription-writing software?</h3>
            <p>
              There are a few existing products on the market, but each one falls short in
              significant areas. We analysed every available option on the market and addressed each
              limitation to make a truly effortless software experience for you, the clinician. A
              few of those improvements include:
            </p>
            <ol className="about-ol">
              <li>
                OptomRx is available to everyone, regardless of which medical record software you
                use. If you have a phone or computer and access to a printer, you can use OptomRx.{' '}
              </li>
              <li>
                All medication and PBS data are up-to-date and preloaded into our database, meaning
                you will never have to manually enter a new drug to prescribe.
              </li>
              <li>
                We store all streamlined authority codes and PBS requirements on our end. This means
                you&apos;ll never have to go searching for an authority code or PBS restriction
                again.
              </li>
              <li>
                Usability and aesthetics have been designed with ease of use and speed in mind. You
                can write and print a PBS-script in under a minute, start to finish.
              </li>
              <li>
                Can&apos;t remember the brand name? Search by medication. Can&apos;t remember the
                medication spelling? Search by brand. OptomRx provides instant auto-complete
                functionality and will pre-fill your selection based on whatever you choose to type.
                Finding the right medication has never been easier.
              </li>
            </ol>
            <p>
              Want to learn more? Check out our{' '}
              <Anchor href="/features" target="_blank" rel="noreferrer">
                features page
              </Anchor>{' '}
              for more details.
            </p>
          </section>

          <section className="About__section">
            <h3>Who is behind the project?</h3>
            <p>
              OptomRx started its life as a passion project back in 2019. The core functions and
              design were created by{' '}
              <Anchor
                href="https://www.linkedin.com/in/danielmoderiano/"
                target="_blank"
                rel="noreferrer"
              >
                Daniel Moderiano
              </Anchor>
              , an Australian Optometrist and Web Developer. Contributions to UI/UX and QA along the
              way came from the help of{' '}
              <Anchor
                href="https://www.linkedin.com/in/sarah-smoker-538b74242/"
                target="_blank"
                rel="noreferrer"
              >
                Sarah Smoker
              </Anchor>
              .
            </p>
            <p>
              Over the years, it became apparent that the profession was lacking a good prescription
              writing software option, and that OptomRx could be a real asset to the profession.
            </p>
            <p>
              As a result, in 2023 OptomRx doubled in size.{' '}
              <Anchor
                href="https://www.linkedin.com/in/sam-hobbs/"
                target="_blank"
                rel="noreferrer"
              >
                Sam Hobbs
              </Anchor>{' '}
              is an Australian Optometrist who came on board and co-founded the new version of
              OptomRx. The aim was to make a solid software product with constant upgrades and great
              user support. Whilst Daniel is responsible for all things coding, Sam has contributed
              to all new additions to the current project as well as front-end features like logo
              design, added functions, and peripheral support (such as email support and LinkedIn
              presence).
            </p>
            <p>
              Since then, Sam and Dan have been working consistently to create what is now labelled
              as <i>OptomRx Beta</i>. In the coming months, major upgrades will be completed and
              implemented, marking the official release of OptomRx 1.0.0.
            </p>
            <p>
              Early user support is what motivated Dan and Sam to make OptomRx a fully-fledged
              product. Thanks to our 163 users at the time of writing, we are now committed to
              making OptomRx a product that is best-in-field for therapeutic prescription writing
              and will continue to adapt and change to the therapeutic landscape.
            </p>
          </section>
        </StyledAbout>
      </LandingPageContainer>
    </>
  );
};

export default About;
