import {
  Anchor,
  Button,
  Modal,
  PasswordInput,
  TextInput,
  Tooltip,
  createTheme,
} from '@mantine/core';
import buttonClasses from './Button.module.css';
import textInputClasses from './TextInput.module.css';
import tooltipClasses from './Tooltip.module.css';
import anchorClasses from './Anchor.module.css';
import modalClasses from './Modal.module.css';

export const theme = createTheme({
  defaultRadius: 'sm',
  primaryColor: 'teal',
  colors: {
    teal: [
      '#e4efee',
      '#c3e0dc',
      '#9fcfca',
      '#81c1ba',
      '#6fb9b0',
      '#63b5ab',
      '#529f95',
      '#468e85',
      '#337b73',
      '#1e6d64',
      '#095f55',
      '#0b4740',
    ],
    paleBlue: [
      '#eef3ff',
      '#dce4f5',
      '#b9c7e2',
      '#94a8d0',
      '#748dc1',
      '#5f7cb8',
      '#5474b4',
      '#44639f',
      '#39588f',
      '#2d4b81',
    ],
  },
  components: {
    Anchor: Anchor.extend({
      classNames: anchorClasses,
      defaultProps: {
        underline: 'always',
      },
    }),
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        loaderProps: { type: 'dots' },
      },
    }),
    TextInput: TextInput.extend({
      classNames: textInputClasses,
      defaultProps: {
        size: 'md',
      },
    }),
    PasswordInput: PasswordInput.extend({
      classNames: textInputClasses,
      defaultProps: {
        size: 'md',
      },
    }),
    Tooltip: Tooltip.extend({
      classNames: tooltipClasses,
      defaultProps: {
        withArrow: true,
        multiline: true,
      },
    }),
    Modal: Modal.extend({
      classNames: modalClasses,
      defaultProps: {
        size: 500,
      },
    }),
  },
});
