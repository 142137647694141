import Subheading from '../../utils/Subheading/Subheading';
import classes from '../Admin.module.css';
import { UsersWithMultiplePrescribers } from './UsersWithMultiplePrescribers';
import { UsersWithEmptyFields } from './UsersWithEmptyFields';
import { PopulateMissingUserFields } from './PopulateMissingUserFields';
import { UsersWithMissingFields } from './UsersWithMissingFields';
import { ShowUserPractices } from './ShowUserPractices';
import { ManageSuspiciousUsers } from './ShowSuspiciousUsers';
import { MigratePrescribersToPractices } from './MigratePrescribersToPractices';

export function MigrationSection() {
  return (
    <section className={classes.section}>
      <Subheading text="Migration to Beta" />
      <p>This section provides some utilities to help manage migrations to the Beta product.</p>
      <br />
      <UsersWithMultiplePrescribers />
      <UsersWithEmptyFields />
      <UsersWithMissingFields />
      <PopulateMissingUserFields />
      {/* TODO: UI-based selection of which prescriber to keep */}
      <ShowUserPractices />
      <ManageSuspiciousUsers />
      <MigratePrescribersToPractices />
    </section>
  );
}
