import Header from './components/Header/Header';
import GlobalStyles from './components/utils/globalStyles';
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import Signup from './components/Signup/Signup';
import Login from './components/Login/Login';
import { useAuthContext } from './hooks/useAuthContext';
import Dashboard from './components/Dashboard/Dashboard';
import Practices from './components/Practices/Practices';
import './App.css';
import EditPractice from './components/EditPractice/EditPractice';
import AddPractice from './components/AddPractice/AddPractice';
import Main from './components/Main/Main';
import Scripts from './components/Scripts/Scripts';
import ViewScript from './components/ViewScript/ViewScript';
import Settings from './components/Settings/Settings';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { StyledApp } from './App.styled';
import Home from './components/Home/Home';
import HomeFooter from './components/Footer/HomeFooter';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import Terms from './components/Policies/Terms';
import Features from './components/Features/Features';
import { useGoogleAPI } from './hooks/useGoogleAPI';
import About from './components/About/About';
import FAQ from './components/FAQ/FAQ';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PrescriptionRoutes } from './components/PrescriptionRoutes/PrescriptionRoutes';
import NotFound from './components/NotFound/NotFound';
import { MantineProvider } from '@mantine/core';
import { theme } from './styles/theme';
import { Admin } from './components/Admin/Admin';
import { AuthenticatedUser } from './context/AuthContext';

export type PageName =
  | 'form'
  | 'login'
  | 'signup'
  | 'scripts'
  | 'settings'
  | 'practices'
  | 'features'
  | 'home';

const handleAdminPageRouting = (user: AuthenticatedUser | null) => {
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (user.isAdmin) {
    return <Admin />;
  }
  return <Navigate to="/dashboard" />;
};

const App = () => {
  // Can use the user state to conditionally render or redirect routes (logged in vs out for example)
  const { user, authIsReady } = useAuthContext();
  const { loadGoogleAPI, googleLoaded } = useGoogleAPI();
  const { pathname } = useLocation();

  const [firstSignIn, setFirstSignIn] = useState(false);

  // Ensure the window is scrolled to the top when changing any routes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Only load google API services when the user is logged in
  useEffect(() => {
    if (user) {
      loadGoogleAPI();
    }
  }, [googleLoaded, loadGoogleAPI, user]);

  return (
    <MantineProvider theme={theme}>
      <StyledApp className="App">
        <GlobalStyles />
        <Toaster position="bottom-center" />
        {authIsReady && (
          <>
            <Header />

            <Main isLoggedIn={user != null}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      {!user && <Home />}
                      {user && (
                        <>
                          <Dashboard
                            user={user}
                            firstSignIn={firstSignIn}
                            setFirstSignIn={setFirstSignIn}
                          />
                        </>
                      )}
                    </>
                  }
                />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/terms-of-service" element={<Terms />} />

                <Route path="/features" element={<Features />} />

                <Route path="/faq" element={<FAQ />} />

                <Route path="/about" element={<About />} />

                <Route path="/admin" element={handleAdminPageRouting(user)} />

                <Route
                  path="/dashboard"
                  element={
                    <>
                      {!user && <Navigate to="/login" />}
                      {user && (
                        <>
                          <Dashboard
                            user={user}
                            firstSignIn={firstSignIn}
                            setFirstSignIn={setFirstSignIn}
                          />
                        </>
                      )}
                    </>
                  }
                />

                <Route
                  path="/settings/*"
                  element={
                    <>
                      {!user && <Navigate to="/login" />}
                      {user && <Settings user={user} />}
                    </>
                  }
                />

                <Route
                  path="/prescription/*"
                  element={
                    <>
                      {user ? (
                        <PrescriptionRoutes user={user} googleLoaded={googleLoaded} />
                      ) : (
                        <Navigate to="/login" />
                      )}
                    </>
                  }
                />

                <Route
                  path="/signup"
                  element={
                    <>
                      {!user && <Signup setFirstSignIn={setFirstSignIn} />}
                      {user && <Navigate to="/dashboard" />}
                    </>
                  }
                />

                <Route
                  path="/login"
                  element={
                    <>
                      {!user && <Login />}
                      {user && <Navigate to="/dashboard" />}
                    </>
                  }
                />

                <Route
                  path="/edit-practice/:id"
                  element={
                    <>
                      {user && <EditPractice googleLoaded={googleLoaded} user={user} />}
                      {!user && <Navigate to="/login" />}
                    </>
                  }
                />

                <Route
                  path="/add-practice"
                  element={
                    <>
                      {user && <AddPractice googleLoaded={googleLoaded} user={user} />}
                      {!user && <Navigate to="/login" />}
                    </>
                  }
                />

                <Route
                  path="/practices"
                  element={
                    <>
                      {user && <Practices user={user} />}
                      {!user && <Navigate to="/login" />}
                    </>
                  }
                />

                <Route
                  path="/scripts"
                  element={
                    <>
                      {user && <Scripts user={user} />}
                      {!user && <Navigate to="/login" />}
                    </>
                  }
                />

                <Route
                  path="/scripts/:id"
                  element={
                    <>
                      {user && <ViewScript user={user} />}
                      {!user && <Navigate to="/login" />}
                    </>
                  }
                />

                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Main>
            {!user && <HomeFooter />}
          </>
        )}
      </StyledApp>
    </MantineProvider>
  );
};

export default App;
