import { useLogin } from '../../hooks/useLogin';
import { Link } from 'react-router-dom';
import { StyledLogin } from './Login.styled';
import { Helmet } from 'react-helmet-async';
import { Button, TextInput, Alert, PasswordInput, Anchor, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { handleCheckboxEnterPress, validateRequiredInput } from '../../utils/formUtils';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from '../../styles/Alert.module.css';
import { browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';

type FormValues = {
  email: string;
  password: string;
  shouldRememberLogin: boolean;
};

const Login = () => {
  const { error, login, isPending, setError } = useLogin();

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      shouldRememberLogin: false,
    },

    validate: {
      email: (value) => validateRequiredInput(value, 'Please enter an email address'),
      password: (value) => validateRequiredInput(value, 'Please enter a password'),
    },
  });

  return (
    <>
      <Helmet>
        <title>Log in · OptomRx</title>
        <meta
          name="description"
          content="A new way for optometrists to professionally create and manage therapeutic prescriptions online."
        />
        <link rel="canonical" href="/login" />
      </Helmet>
      <StyledLogin className="Login">
        <div className="Login__container">
          <h2 className="Login__title">Log in</h2>

          <form
            className="Login__form"
            noValidate
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              const persistence = values.shouldRememberLogin
                ? browserLocalPersistence
                : browserSessionPersistence;
              login(values.email, values.password, persistence);
            })}
          >
            <TextInput
              label="Email"
              autoComplete="username"
              className="auth-field form-field"
              type="email"
              {...form.getInputProps('email')}
              onChange={(event) => {
                // Must call the original onChange handler from form.getInputProps
                form.getInputProps('email').onChange(event);
                // Custom onChange logic
                setError('');
              }}
            />
            <PasswordInput
              label="Password"
              autoComplete="password"
              className="auth-field form-field"
              {...form.getInputProps('password')}
              onChange={(event) => {
                // Must call the original onChange handler from form.getInputProps
                form.getInputProps('password').onChange(event);
                // Custom onChange logic
                setError('');
              }}
              size="md"
            />

            <Checkbox
              // TODO: Import checkbox style classes
              className="rememberMe"
              label="Remember me"
              onKeyDown={handleCheckboxEnterPress}
              {...form.getInputProps('shouldRememberLogin', { type: 'checkbox' })}
            />

            {error ? (
              <Alert
                variant="light"
                icon={<IconInfoCircle />}
                color="red"
                className={classes.errorAlert}
              >
                {error}
              </Alert>
            ) : null}

            <Button
              className="Login__btn"
              type="submit"
              variant="primary"
              loading={isPending}
              size="md"
            >
              Log in
            </Button>
            <Button
              component={Link}
              to="/reset-password"
              variant="transparent"
              className="forgot-password"
            >
              Forgot password?
            </Button>
          </form>

          <div className="signup-option">
            <span className="signup-msg">Don&apos;t have an account?</span>
            <Anchor component={Link} to="/signup" className="signup-link">
              Sign up.
            </Anchor>
          </div>
        </div>
      </StyledLogin>
    </>
  );
};

export default Login;
