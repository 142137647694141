import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';
import { z } from 'zod';
import { logError } from '../utils/logError';

export type UseDocumentOptions = {
  includeDocumentIds?: boolean;
  /**
   * Whether to throw an error if the document cannot be found. Defaults to true. Useful in cases
   * where you expect a document may not exist on initial render, but should appear shortly after.
   */
  errorOnMissingDocument?: boolean;
};

/**
 * A hook for fetching a single document from Firestore using a real-time listener.
 *
 * @template S The Zod schema type for the expected document data.
 * @param path The path to the document in Firestore.
 * @param schema The Zod schema to validate the document data.
 * @param options Optional. Configuration options for the hook.
 * @returns An object containing the document data, loading state, and any error.
 *
 * @example
 * // Usage with a specific schema for the document data
 * const { data, isLoading, error } = useDocument(
 *    'users/userId',
 *    userDocumentSchema,
 *    { includeDocumentIds: true }
 * );
 */
export function useDocument<S extends z.ZodType>(
  path: string,
  schema: S,
  options: UseDocumentOptions = {
    includeDocumentIds: false,
    errorOnMissingDocument: true,
  },
) {
  type DataType = z.infer<S>;
  const [data, setData] = useState<DataType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  const { includeDocumentIds, errorOnMissingDocument } = options;

  useEffect(() => {
    const documentRef = doc(db, path);
    const unsubscribe = onSnapshot(
      documentRef,
      (documentSnapshot) => {
        if (!documentSnapshot.exists()) {
          if (errorOnMissingDocument) {
            setError(new Error(`Document at path "${path}" does not exist.`));
          } else {
            setData(null);
          }
          setIsLoading(false);
        } else {
          let documentData = documentSnapshot.data();
          if (includeDocumentIds) {
            documentData = { ...documentData, id: documentSnapshot.id };
          }

          try {
            const validatedData = schema.parse(documentData);
            setData(validatedData);
            setError(null); // Reset error state on successful validation
          } catch (validationError) {
            setError(validationError);
            logError(error);
          } finally {
            setIsLoading(false);
          }
        }
      },
      (error) => {
        // Handle Firestore errors
        setError(error);
        setIsLoading(false);
        logError(error);
      },
    );

    return () => unsubscribe();
  }, [path, includeDocumentIds, schema, errorOnMissingDocument, error]);

  return { data, isLoading, error };
}
