import { useState, useEffect } from 'react';

function usePagination<T>(items: T[], itemsPerPage: number) {
  const [currentItems, setCurrentItems] = useState<T[]>([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    // Set the initial items for the first page
    setCurrentItems(items.slice(0, itemsPerPage));
    // Calculate the total pages required
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [items, itemsPerPage]);

  const setItemsForCurrentPage = (currentPage: number) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentItems(items.slice(start, end));
  };

  return { currentItems, setItemsForCurrentPage, pageCount };
}

export default usePagination;
