import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import DevErrorBoundary from './DevErrorBoundary';

let ErrorBoundaryComponent: BugsnagErrorBoundary = DevErrorBoundary;

// Only start Bugsnag in production environments, otherwise we end up with a total overload of
// error reporting from development
if (import.meta.env.MODE === 'production') {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
  });

  const bugsnagReact = Bugsnag.getPlugin('react');

  ErrorBoundaryComponent = bugsnagReact
    ? bugsnagReact.createErrorBoundary(React)
    : DevErrorBoundary;
}

const ErrorBoundary = ({
  children,
  FallbackComponent,
}: {
  children?: React.ReactNode | undefined;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
    clearError: () => void;
  }>;
}) => (
  <ErrorBoundaryComponent FallbackComponent={FallbackComponent}>{children}</ErrorBoundaryComponent>
);

export default ErrorBoundary;
