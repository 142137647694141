import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../firebase/config';
import PracticeForm from '../PracticeForm/PracticeForm';
import { StyledAddPractice } from './AddPractice.styled';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { PracticeFormValues } from '../../types/firestore';
import toast from 'react-hot-toast';
import { useUserDocument } from '../../hooks/useUserDocument';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { PrescriberDetails } from '../PrescriberDetails/PrescriberDetails';
import { logError } from '../../utils/logError';

type AddPracticeProps = {
  googleLoaded: boolean;
  user: User;
};

const AddPractice = ({ googleLoaded, user }: AddPracticeProps) => {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);

  const { data: userDocument, error: userError } = useUserDocument(user.uid);

  useHandleFetchError(userError);

  // Used when the user submits the form - saves a new practice referenced by their user ID
  const handleSubmit = async (values: PracticeFormValues) => {
    setIsPending(true);

    try {
      // Update data on backend
      await addDoc(collection(db, 'practices'), {
        ...values,
        default: false,
        userId: user.uid,
      });

      // Must reset state here before navigating back to the previous page (component will no longer be mounted otherwise)
      setIsPending(false);

      // Confirm save via toast message, and return to the previous page
      toast.success('New practice added');
      navigate('/practices');
    } catch (error) {
      logError(error);
      setIsPending(false);
      // Throw error toast on screen, no further rendering is required, nor any specific error handling
      toast.error('An error occurred while adding practice');
    }
  };

  const cancelEdit = () => {
    navigate('/practices');
  };

  return (
    <>
      <Helmet>
        <title>Add practice · OptomRx</title>
        <meta
          name="description"
          content="Add new practice details for use with your prescriptions."
        />
        <link rel="canonical" href="/add-practice" />
      </Helmet>
      <ContentContainer>
        <StyledAddPractice>
          <PageHeader
            title="Add practice"
            description="Practice details will appear on your prescriptions"
            rightSection={<PrescriberDetails userDocument={userDocument} />}
          />
          <div className="form-container">
            <span className="form-title">Practice details</span>
            <PracticeForm
              googleLoaded={googleLoaded}
              handleSubmit={handleSubmit}
              handleCancel={cancelEdit}
              submitBtnLabel="Add practice"
              pending={isPending}
            />
          </div>
        </StyledAddPractice>
      </ContentContainer>
    </>
  );
};

export default AddPractice;
