import { styled } from 'styled-components';

const StyledHeader = styled.header<{ $isUser: boolean }>`
  /* Using user prop to either show the logged in or logged out styling */
  color: #ffffff;
  background-color: ${(props) => (props.$isUser ? 'var(--primary-color)' : '#fff')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.$isUser ? '0 1.5rem' : '1rem 2rem 1.5rem 2rem')};
  width: 100%;
  height: ${(props) => (props.$isUser ? '3.8rem' : '5.8rem')};
  flex-shrink: 0;

  .logo-container {
    position: relative;
  }

  &.auth-header {
    background-color: var(--background);
  }

  h1 {
    margin: ${(props) => (props.$isUser ? '0.2rem 0 0.1rem 0' : '0.85rem 0 0 0')};
    width: 7rem;

    &:hover {
      cursor: pointer;
    }

    .cls-1 {
      fill: ${(props) => (props.$isUser ? '#fff' : 'var(--primary-color)')};
    }

    svg {
      pointer-events: none;
    }

    a {
      &:focus {
        outline: 2px solid #1b1e22;
        box-shadow: 0 0 0 4px white;
      }

      &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: none;
      }

      &:focus-visible {
        outline: 2px solid #1b1e22;
        box-shadow: 0 0 0 4px white;
      }
    }
  }

  /* Avoid messing up the printed RxTemplate */
  @media print {
    display: none;
  }

  @media (min-width: 1600px) {
    padding: ${(props) => (props.$isUser ? '0 1.5rem' : '1rem 5rem 1.5rem 5rem')};
  }

  @media (max-width: 768px) {
    padding: ${(props) => (props.$isUser ? '0 1.5rem' : '1rem 1.5rem 1.5rem 1.5rem')};
  }
`;

export { StyledHeader };
