import { styled } from 'styled-components';

const StyledSubheading = styled.div`
  display: block;
  padding: 0;
  border-bottom: 1px solid #dfe1e1;
  padding-bottom: 0.5rem;
  margin-bottom: 0.75rem;
  font-size: 1.6rem;
  font-weight: 200;
  font-family: var(--font-stack-segoe);
  color: var(--title-color);
`;

export const Subheading = ({ text }: { text: string }) => {
  return <StyledSubheading>{text}</StyledSubheading>;
};

export default Subheading;
