import { collection, getDocs } from 'firebase/firestore';
import { Button, Table } from '@mantine/core';
import { useState } from 'react';
import { db } from '../../../firebase/config';
import { LegacyPrescriberDocument, UserDocument } from '../../../types/firestore';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';

type UserInfo = {
  id: string;
  fullName?: string;
  prescriberInfo?: LegacyPrescriberDocument[];
};

export function filterUniquePrescribers(
  prescribers: LegacyPrescriberDocument[],
): LegacyPrescriberDocument[] {
  const uniquePrescriberMap = new Map<string, LegacyPrescriberDocument>();

  prescribers.forEach((prescriber) => {
    if (!uniquePrescriberMap.has(prescriber.prescriberNumber)) {
      uniquePrescriberMap.set(prescriber.prescriberNumber, prescriber);
    }
  });

  return Array.from(uniquePrescriberMap.values());
}

export function UsersWithMultiplePrescribers() {
  // FIXME: Abstract each button to its own component to separate loading state and fetch functionality
  const [isLoading, setIsLoading] = useState(false);
  const [usersWithMultiplePrescribers, setUsersWithMultiplePrescribers] = useState<UserInfo[]>([]);

  /**
   * Show users with multiple prescribers, based on the prescriber number.
   */
  async function showUsersWithMultiplePrescribers() {
    const usersWithMultiplePrescribersData: UserInfo[] = [];

    try {
      setIsLoading(true);

      // Reference to the 'users' and 'prescribers' collections
      const usersCollection = collection(db, 'users');
      const prescriberCollection = collection(db, 'prescribers');

      // Get all documents from the 'users' collection
      const userQuerySnapshot = await getDocs(usersCollection);
      // Get all documents from the 'prescribers' collection
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      // Map prescribers to an array
      const allPrescribers = prescriberQuerySnapshot.docs.map(
        (doc) => doc.data() as LegacyPrescriberDocument,
      );

      // Iterate through each user document
      userQuerySnapshot.forEach((doc) => {
        const userData = doc.data() as UserDocument;

        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        // Filter unique prescribers based on prescriberNumber
        const uniquePrescribers = filterUniquePrescribers(relatedPrescribers);

        // Check if user has more than one related prescriber
        if (uniquePrescribers.length > 1) {
          usersWithMultiplePrescribersData.push({
            id: doc.id,
            fullName: userData.fullName,
            prescriberInfo: relatedPrescribers,
          });
        }
      });

      setUsersWithMultiplePrescribers(usersWithMultiplePrescribersData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error scanning users collection:', error);
    }
  }

  // Render the users with multiple prescribers in a Mantine table
  const userRows = usersWithMultiplePrescribers.map((user) => (
    <Table.Tr key={user.id}>
      <Table.Td>{user.id}</Table.Td>
      <Table.Td>{user.fullName ?? 'No Full Name'}</Table.Td>
      <Table.Td>
        {user.prescriberInfo?.map((prescriber) => prescriber.fullName).join(', ')}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Users with multiple prescribers</h4>
      <p>
        Click this button to view a table of all users with multiple prescribers to a single
        account, based on prescriber number.
      </p>
      <div className={migrationClasses.buttonsContainer}>
        <Button
          onClick={showUsersWithMultiplePrescribers}
          loading={isLoading}
          className={migrationClasses.button}
        >
          Show users with multiple prescribers
        </Button>
        <Button
          onClick={() => setUsersWithMultiplePrescribers([])}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>
      {usersWithMultiplePrescribers.length > 0 && (
        <Table className={migrationClasses.table} striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>User ID</Table.Th>
              <Table.Th>Full Name</Table.Th>
              <Table.Th>Prescribers</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{userRows}</Table.Tbody>
        </Table>
      )}
    </section>
  );
}
