import { useParams, Link } from 'react-router-dom';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import './ViewScript.css';
import { useFormatting } from '../../hooks/useFormatting';
import Spinner from '../utils/Spinner/Spinner';
import { Helmet } from 'react-helmet-async';
import { StyledViewScript } from './ViewScript.styled';
import { prescriptionDocumentSchema } from '../../types/firestore';
import { useDocument } from '../../hooks/useDocument';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import type { User } from 'firebase/auth';
import { Button } from '@mantine/core';
import { IconStar, IconArrowForward } from '@tabler/icons-react';
import { AddFavouriteModal } from './AddFavouriteModal';
import { useDisclosure } from '@mantine/hooks';

type ViewScriptProps = {
  user: User;
};

const ViewScript = ({ user }: ViewScriptProps) => {
  const { id } = useParams();
  const { formatDrug, formatDate } = useFormatting();
  const {
    data: scriptData,
    isLoading,
    error,
  } = useDocument(`scripts/${id}`, prescriptionDocumentSchema);
  useHandleFetchError(error);

  const [isModalVisible, { open: openModal, close: closeModal }] = useDisclosure(false);

  return (
    <>
      <Helmet>
        <title>View script · OptomRx</title>
        <meta
          name="description"
          content="View medication and PBS details for the selected script. Patient data is not retained."
        />
      </Helmet>
      <ContentContainer>
        <StyledViewScript>
          <PageHeader title={`Script #${id}`} />
          {isLoading && <Spinner />}
          {scriptData && (
            <>
              <div className="Script__info">
                <div className="Script__medication">
                  <div className="Script__title Script__title--medication">Medication details</div>
                  <div className="Script__info--section Script__drug">{formatDrug(scriptData)}</div>
                  {scriptData.compounded && (
                    <div className="Script__info--section Script__compounded">To be compounded</div>
                  )}
                  <div className="Script__info--section Script__substitute">{`${
                    scriptData.substitutePermitted
                      ? 'Brand substitution allowed'
                      : 'Brand substitution not permitted'
                  }`}</div>
                  <div className="Script__info--section Script__dosage">
                    Dosage: {scriptData.dosage}
                  </div>
                  <div className="Script__info--section Script__quantity">
                    Quantity: {scriptData.quantity}
                  </div>
                  <div className="Script__info--section Script__repeats">
                    Repeats: {scriptData.repeats}
                  </div>
                </div>

                <div className="Script__pbs">
                  <div className="Script__title Script__title--pbs">PBS details</div>
                  <div className="Script__info--section Script__pbs">{`${
                    scriptData.pbsRx ? 'PBS prescription' : 'Non-PBS prescription'
                  }`}</div>

                  {scriptData.authRequired && (
                    <div className="Script__authority">
                      <div className="Script__info--section Script__authCode">
                        Authority code: {scriptData.authCode}
                      </div>
                      <div className="Script__info--section Script__authNum">
                        Authority Rx No: {scriptData.authRxNumber}
                      </div>
                      <div className="Script__info--section Script__indications">
                        Clinical justification for use of item: {scriptData.justification}
                      </div>
                    </div>
                  )}
                  {scriptData.dateTime ? (
                    <div className="Script__info--section Script__date">
                      Prescribed: {scriptData.dateTime}
                    </div>
                  ) : (
                    <div className="Script__info--section Script__date">
                      Date prescribed: {formatDate(scriptData.date)}
                    </div>
                  )}
                </div>
              </div>

              <div className="PrescriberForm__btns">
                <Button
                  component={Link}
                  variant="primary"
                  leftSection={<IconArrowForward width={20} />}
                  to="/prescription/new"
                  state={{
                    newRx: true,
                    rePrescribe: true,
                    scriptData: scriptData,
                  }}
                >
                  Re-prescribe
                </Button>

                <Button variant="primary" leftSection={<IconStar width={20} />} onClick={openModal}>
                  Add to favourites
                </Button>
              </div>
            </>
          )}

          {scriptData ? (
            <AddFavouriteModal
              isOpen={isModalVisible}
              onClose={closeModal}
              user={user}
              prescription={scriptData}
            />
          ) : null}
        </StyledViewScript>
      </ContentContainer>
      <p className="bottom-text">
        OptomRx prioritises patient privacy by not storing any personal details. Only medication
        information is retained for review.
      </p>
    </>
  );
};

export default ViewScript;
