import { useForm } from '@mantine/form';
import { validateRequiredInput } from '../../utils/formUtils';
import settingsClasses from './Settings.module.css';
import { DeleteAccountModal } from './DeleteAccountModal';
import { useDisclosure } from '@mantine/hooks';
import type { User } from 'firebase/auth';
import { Button } from '@mantine/core';

type DeleteAccountProps = {
  user: User;
};

type FormValues = {
  password: string;
};

const DeleteAccount = ({ user }: DeleteAccountProps) => {
  const [isModalVisible, { open: openModal, close: closeModal }] = useDisclosure(false);

  const form = useForm<FormValues>({
    initialValues: {
      password: '',
    },

    validate: {
      password: (value) => validateRequiredInput(value, 'Please enter a password'),
    },
  });

  return (
    <>
      <div className="delete-account">
        <div className="form-title form-title--delete">Delete account</div>
        <p className="warning">Once you delete your account, it is permanent. Please be sure.</p>
        <Button
          variant="danger"
          onClick={() => {
            openModal();
            form.reset();
          }}
          classNames={{ root: settingsClasses.deleteButton }}
        >
          Delete account
        </Button>
      </div>
      <DeleteAccountModal isOpen={isModalVisible} onClose={closeModal} user={user} />
    </>
  );
};

export default DeleteAccount;
