import { useDocument } from './useDocument';
import { userDocumentSchema, userDocumentWithIdSchema } from '../types/firestore';

/**
 * Simple wrapper around useDocument that gets the User document for the provided ID.
 */
export const useUserDocument = (userId: string) => {
  return useDocument(`users/${userId}`, userDocumentSchema, {
    includeDocumentIds: false,
    errorOnMissingDocument: true,
  });
};

/**
 * Simple wrapper around useDocument that gets the User document for the provided ID, including
 * the document ID in the result.
 */
export const useUserDocumentWithId = (userId: string) => {
  return useDocument(`users/${userId}`, userDocumentWithIdSchema, {
    includeDocumentIds: true,
    errorOnMissingDocument: true,
  });
};
