import { StyledPractices } from './Practices.styled';
import { Link } from 'react-router-dom';
import PracticesTable from './PracticesTable';
import Spinner from '../utils/Spinner/Spinner';
import { useCallback } from 'react';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { useCollection } from '../../hooks/useCollection';
import { PracticeDocumentWithId, practiceDocumentWithIdSchema } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { Button } from '@mantine/core';
import { where } from 'firebase/firestore';

type PracticesProps = {
  user: User;
};

export type EnrichedPractice = PracticeDocumentWithId & {
  fullName: string | null;
  qualifications: string | null;
  prescriberNumber: string | null;
};

const Practices = ({ user }: PracticesProps) => {
  const getQueryConstraints = useCallback(() => [where('userId', '==', user.uid)], [user.uid]);
  const {
    data: practices,
    isLoading,
    error,
  } = useCollection('practices', practiceDocumentWithIdSchema, {
    includeDocumentIds: true,
    getQueryConstraints,
  });

  useHandleFetchError(error);

  return (
    <>
      <Helmet>
        <title>Practices · OptomRx</title>
        <meta
          name="description"
          content="Add, edit, or delete practice details. Use different practices as needed when you create new scripts."
        />
        <link rel="canonical" href="/practices" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledPractices className="Practices">
          <PageHeader
            title="Practices"
            description="Add and modify practice details for your prescriptions"
          />

          <Button component={Link} variant="primary" to="/add-practice">
            Add new practice
          </Button>

          <div className="Practices__container">
            {isLoading && <Spinner />}

            {practices && (
              <>
                {practices.length > 0 ? (
                  <PracticesTable data={practices} rowsPerPage={10} />
                ) : (
                  <table className="table table-none">
                    <thead className="tableRowHeader">
                      <tr>
                        <th className="tableHeader">Name</th>
                        <th className="tableHeader">Location</th>
                        <th className="tableHeader actions-header">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tableRowItems">
                        <td data-title="Practices" className="tableCellNone" colSpan={3}>
                          No practices added yet
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </StyledPractices>
      </ContentContainer>
    </>
  );
};

export default Practices;
