// Non-specific error handling for any relevant forms submitting auth data. Uses firebase known errors.
export const useErrorHandling = () => {
  const handleSettingsError = (errorCode: string) => {
    let errorMessage = '';

    switch (errorCode) {
      case 'auth/wrong-password':
        errorMessage = "That's an incorrect password. Try again.";
        break;
      case 'auth/missing-email':
        errorMessage = 'Please enter an email address.';
        break;
      case 'auth/weak-password':
        errorMessage = 'Please create a password at least six characters in length.';
        break;
      case 'auth/user-not-found':
        errorMessage =
          "We couldn't find an account with that email address. Check for typos and try again.";
        break;
      case 'auth/invalid-email':
        errorMessage = 'Please enter a valid email address.';
        break;
      case 'auth/too-many-requests':
        errorMessage =
          'Failed to authorise too many times. Please wait a few minutes before trying again.';
        break;
      case 'auth/network-request-failed':
        errorMessage =
          "We couldn't connect to the network. Please check your internet connection and try again.";
        break;
      default:
        errorMessage = 'An unknown server error occurred. Please try again.';
        break;
    }
    return errorMessage;
  };

  const handleLoginSignupError = (errorCode: string) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'Please enter a valid email address.';
      case 'auth/wrong-password':
        return "That's an incorrect password. Try again.";
      case 'auth/user-not-found':
        return "We couldn't find an account with that email address. Check for typos and try again.";
      case 'auth/too-many-requests':
        return 'Failed to login too many times. Please wait a few minutes before trying again.';
      case 'auth/network-request-failed':
        return "We couldn't connect to the network. Please check your internet connection and try again.";
      case 'auth/email-already-in-use':
        return 'This email is already in use. Try another.';
      case 'auth/weak-password':
        return 'Please create a password at least six characters in length.';
      default:
        return 'An unknown server error occurred. Please try again.';
    }
  };

  return { handleSettingsError, handleLoginSignupError };
};
