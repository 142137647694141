import { Address, PrescriptionFormValues } from '../../types/firestore';
import { UseFormReturnType } from '@mantine/form';
import { useCallback } from 'react';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import classes from '../RxForm/RxForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';
import { Tooltip } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';

type PatientDetailsProps = {
  form: UseFormReturnType<PrescriptionFormValues>;
  googleLoaded: boolean;
};

const IrnLabel = () => {
  return (
    <div className={classes.irnLabel}>
      <span>IRN</span>
      <Tooltip label="Individual Reference Number" position="top">
        <IconHelp
          aria-label="Show full description for 'IRN'"
          width={18}
          className={classes.helpIcon}
        />
      </Tooltip>
    </div>
  );
};

// Created to help parent RxForm component readability
const PatientDetails = ({ form, googleLoaded }: PatientDetailsProps) => {
  const { setFieldValue } = form;

  const handleAddressChange = useCallback(
    (address: Address) => {
      // Setting bulk values with `setValues` loses type safety, so fields are set individually
      setFieldValue('patientData.streetAddress', address.streetAddress);
      setFieldValue('patientData.suburb', address.suburb);
      setFieldValue('patientData.subpremise', address.subpremise);
      setFieldValue('patientData.state', address.state);
      setFieldValue('patientData.postcode', address.postcode);
    },
    [setFieldValue],
  );

  return (
    <>
      <TextInputWithCheckmark
        classNames={{ root: classes.fullNameInput }}
        label="Full name"
        {...form.getInputProps('patientData.fullName')}
        isValid={form.isValid('patientData.fullName')}
      />
      <AddressAutocomplete
        googleLoaded={googleLoaded}
        form={form}
        handleAddressChange={handleAddressChange}
        fieldPath="patientData"
      />
      <div className="medicareFields">
        <TextInputWithCheckmark
          classNames={{
            root: `${classes.formInput} ${classes.medicareNumberInput}`,
            error: classes.medicareNumberError,
          }}
          label="Medicare number"
          {...form.getInputProps('patientData.medicareNumber')}
          maxLength={10}
          isValid={
            form.isValid('patientData.medicareNumber') &&
            form.values.patientData.medicareRefNumber !== ''
          }
        />
        <TextInputWithCheckmark
          classNames={{
            root: `${classes.formInput} ${classes.referenceNumberInput}`,
            error: classes.referenceNumberError,
          }}
          label={<IrnLabel />}
          {...form.getInputProps('patientData.medicareRefNumber')}
          maxLength={1}
          isValid={
            form.isValid('patientData.medicareRefNumber') &&
            form.values.patientData.medicareNumber !== ''
          }
        />
      </div>
    </>
  );
};

export default PatientDetails;
