import { Helmet } from 'react-helmet-async';
import { SettingsPage } from './Profile';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import { StyledSettings } from './Settings.styled';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import type { User } from 'firebase/auth';

/**
 * Refreshes the user's credentials. Required for account-crucial operations like email change or
 * account deletion.
 * @param user The user to refresh credentials for
 * @param password The user's password
 */
export const refreshCredentials = async (user: User, password: string): Promise<void> => {
  if (!user.email) {
    throw new Error('No user email found');
  }
  const credential = EmailAuthProvider.credential(user.email, password);
  await reauthenticateWithCredential(user, credential);
};

export const Account = ({ user }: { user: User }) => {
  return (
    <>
      <Helmet>
        <title>Account · OptomRx</title>
        <meta name="account" content="Manage your account details." />
        <link rel="canonical" href="/settings/account" />
      </Helmet>
      <SettingsPage>
        <StyledSettings>
          <div className="Settings-container">
            {user.emailVerified ? (
              <>
                <ChangeEmail verified={true} user={user} />
                <ChangePassword user={user} />
              </>
            ) : (
              <ChangeEmail verified={false} user={user} />
            )}
            <DeleteAccount user={user} />
          </div>
        </StyledSettings>
      </SettingsPage>
    </>
  );
};
