import { StyledAuthorityExtract } from './AppExtracts.styled';
import { Checkbox } from '@mantine/core';

const AuthorityExtract = () => {
  return (
    <StyledAuthorityExtract aria-hidden={true}>
      <Checkbox
        className="auth-checkbox"
        label="Authority required"
        description="This item requires an authority prescription"
        defaultChecked
      />

      <div className="AuthNumber">Authority script number: 00028866</div>

      <div className="Input">
        <div className="container">
          <span className="label-text">Authority code</span>
          <div className="input">4105</div>
          <span className="description">
            This medication is available using the streamline code above
          </span>
        </div>
      </div>
    </StyledAuthorityExtract>
  );
};

export default AuthorityExtract;
