import { StyledScriptExtract } from './AppExtracts.styled';
import PageHeader from '../../utils/PageHeader/PageHeader';

const ScriptExtract = () => {
  return (
    <StyledScriptExtract aria-hidden={true}>
      <div className="sc-giYglK kquWbv Script">
        <PageHeader title="Script #00001234" />

        <div className="Script__info">
          <div className="Script__medication">
            <div className="Script__title Script__title--medication">Medication details</div>
            <div className="Script__info--section Script__drug">
              Latanoprost 0.005% (Xalatan) eye drops, 2.5 mL
            </div>
            <div className="Script__info--section Script__substitute">
              Brand substitution allowed
            </div>
            <div className="Script__info--section Script__dosage">
              Dosage: Once nightly both eyes
            </div>
            <div className="Script__info--section Script__quantity">Quantity: 1</div>
            <div className="Script__info--section Script__repeats">Repeats: 5</div>
          </div>

          <div className="Script__pbs">
            <div className="Script__title Script__title--pbs">PBS details</div>
            <div className="Script__info--section Script__pbs">PBS prescription</div>
            <div className="Script__info--section Script__date">
              Prescribed: Feb 13, 2022, 3:15 PM
            </div>
          </div>
        </div>
      </div>
    </StyledScriptExtract>
  );
};

export default ScriptExtract;
