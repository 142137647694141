import { useState } from 'react';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { StyledResetPassword } from './ResetPassword.styled';
import { useNavigate } from 'react-router-dom';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { FirebaseError } from 'firebase/app';
import toast from 'react-hot-toast';
import { Alert, Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateRequiredInput } from '../../utils/formUtils';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from '../../styles/Alert.module.css';
import { logError } from '../../utils/logError';

type FormValues = {
  email: string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { handleSettingsError } = useErrorHandling();

  const [globalError, setGlobalError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => validateRequiredInput(value, 'Please enter an email address'),
    },
  });

  // Attempt to send password link to email provided, and handle any errors that occur
  const resetPassword = async (email: string) => {
    setIsPending(true);
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setIsPending(false);
      toast.success('Reset link has been sent');
      navigate('/login');
    } catch (error) {
      setIsPending(false);
      if (error instanceof FirebaseError) {
        const errorMsg = handleSettingsError(error.code);
        setGlobalError(errorMsg);
      } else {
        logError(error);
      }
    }
  };

  return (
    <StyledResetPassword className="Login">
      <div className="Login__container">
        <h2 className="Login__title">Reset your password</h2>

        <form
          className="Login__form"
          noValidate
          onSubmit={form.onSubmit((values, event) => {
            event?.preventDefault();
            resetPassword(values.email);
          })}
        >
          <TextInput
            label="Enter the email address associated with your account and we'll send you a password reset link."
            autoComplete="username"
            className="auth-field form-field"
            type="email"
            {...form.getInputProps('email')}
            onChange={(event) => {
              // Must call the original onChange handler from form.getInputProps
              form.getInputProps('email').onChange(event);
              // Custom onChange logic
              setGlobalError('');
            }}
          />
          {globalError ? (
            <Alert
              variant="light"
              icon={<IconInfoCircle />}
              color="red"
              className={classes.errorAlert}
            >
              {globalError}
            </Alert>
          ) : null}

          <Button
            className="Login__btn"
            type="submit"
            variant="primary"
            size="md"
            loading={isPending}
          >
            Send password reset email
          </Button>
        </form>
      </div>
    </StyledResetPassword>
  );
};

export default ResetPassword;
