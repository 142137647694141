import { db } from '../../../firebase/config';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';
import { LegacyPrescriberDocument, UserDocument } from '../../../types/firestore';
import { collection, getDocs, updateDoc } from 'firebase/firestore';
import { Button, Accordion, Table, Avatar, Group, Text, Code } from '@mantine/core';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function PopulateMissingUserFields() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);
  const [emptyFieldUserUpdates, setEmptyFieldUserUpdates] = useState<
    {
      userId: string;
      fieldsUpdated: { fullName: string; prescriberNumber: string; qualifications: string };
    }[]
  >([]);
  const [missingFieldUserUpdates, setMissingFieldUserUpdates] = useState<
    { userId: string; fieldsUpdated: string[] }[]
  >([]);

  /**
   * Populate full name, prescriber number, settings, and qualifications fields in user documents
   * where they are missing, or are empty strings.
   *
   * If the user has a full name and prescriber number, no update will be made.
   * Further, if the user has multiple prescribers, no update will be made.
   * For `settings`, the field can only be added, not populated.
   */
  async function populateMissingUserFields() {
    const emptyFieldUserUpdates: {
      userId: string;
      fieldsUpdated: {
        fullName: string;
        prescriberNumber: string;
        qualifications: string;
      };
    }[] = [];
    const missingFieldUserUpdates: { userId: string; fieldsUpdated: string[] }[] = [];

    try {
      setIsLoading(true);

      const usersCollection = collection(db, 'users');
      const prescriberCollection = collection(db, 'prescribers');

      const userQuerySnapshot = await getDocs(usersCollection);
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      // Map prescribers to an array for easier searching and filtering
      const allPrescribers = prescriberQuerySnapshot.docs.map(
        (doc) => doc.data() as LegacyPrescriberDocument,
      );

      for (const doc of userQuerySnapshot.docs) {
        const userData = doc.data() as UserDocument;

        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        const fieldsUpdated = [];
        // Check first if the user document has the required fields at all.
        for (const field of ['fullName', 'prescriberNumber', 'settings', 'qualifications']) {
          if (field === 'settings') {
            await updateDoc(doc.ref, {
              settings: {
                isIndicationsDefaultExpanded: true,
              },
            });
            fieldsUpdated.push(field);
            continue;
          } else if (!(field in userData)) {
            await updateDoc(doc.ref, { [field]: '' });
            fieldsUpdated.push(field);
          }
        }
        if (fieldsUpdated.length > 0) {
          missingFieldUserUpdates.push({ userId: doc.id, fieldsUpdated });
        }

        if (
          userData.fullName &&
          userData.prescriberNumber &&
          userData.fullName.trim() !== '' &&
          userData.prescriberNumber.trim() !== ''
        ) {
          // No sense in updating if the user already has the critical full name and prescriber
          // number fields populated
          continue;
        }

        // The only scenario where we want to update the user document is if the user has only one
        // prescriber, and the user document is missing the full name and prescriber number fields
        if (relatedPrescribers.length >= 1) {
          const prescriber = relatedPrescribers[0];

          const fieldsToUpdate = {
            fullName: prescriber.fullName,
            prescriberNumber: prescriber.prescriberNumber,
            qualifications: prescriber.qualifications,
          };

          await updateDoc(doc.ref, fieldsToUpdate);
          emptyFieldUserUpdates.push({ userId: doc.id, fieldsUpdated: fieldsToUpdate });
        }
      }

      setEmptyFieldUserUpdates(emptyFieldUserUpdates);
      setMissingFieldUserUpdates(missingFieldUserUpdates);
      if (missingFieldUserUpdates.length === 0 && emptyFieldUserUpdates.length === 0) {
        toast.success('No user updates required.');
      } else {
        setIsAccordionVisible(true);
        toast.success('Users updated successfully.');
      }
    } catch (error) {
      console.error('Error scanning users collection:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Safely populate missing user fields</h4>
      <p>
        Click this button to populate missing user fields with values from the related prescriber
        document. This may modify database records.
      </p>
      <div className={migrationClasses.buttonsContainer}>
        <Button
          onClick={populateMissingUserFields}
          loading={isLoading}
          className={migrationClasses.button}
          color="orange"
        >
          Populate missing user fields
        </Button>
        <Button
          onClick={() => setIsAccordionVisible(false)}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>

      {isAccordionVisible ? (
        <Accordion chevronPosition="right" variant="contained" mt="md">
          <Accordion.Item value="missing-fields-summary">
            <Accordion.Control>
              <Group>
                <Avatar color="orange" radius="xl">
                  {missingFieldUserUpdates.length}
                </Avatar>
                <div>
                  <Text>Missing fields updated</Text>
                  <Text size="sm" c="dimmed">
                    {missingFieldUserUpdates.length} users with missing fields added
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {missingFieldUserUpdates.length > 0 ? (
                <Table striped highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>User ID</Table.Th>
                      <Table.Th>Fields added</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {missingFieldUserUpdates.map((result) => (
                      <Table.Tr key={result.userId}>
                        <Table.Td>{result.userId}</Table.Td>
                        <Table.Td>
                          {result.fieldsUpdated.map((field) => (
                            <div key={field}>
                              <Code>{field}</Code>
                            </div>
                          ))}
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              ) : (
                'No fields were updated.'
              )}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="empty-fields-summary">
            <Accordion.Control>
              <Group>
                <Avatar color="teal" radius="xl">
                  {emptyFieldUserUpdates.length}
                </Avatar>
                <div>
                  <Text>Users with empty fields updated</Text>
                  <Text size="sm" c="dimmed">
                    {emptyFieldUserUpdates.length} users with empty fields updated
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {emptyFieldUserUpdates.length > 0 ? (
                <Table striped highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>User ID</Table.Th>
                      <Table.Th>Fields updated</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {emptyFieldUserUpdates.map((result) => (
                      <Table.Tr key={result.userId}>
                        <Table.Td>{result.userId}</Table.Td>
                        <Table.Td>
                          {Object.entries(result.fieldsUpdated).map(([field, value]) => (
                            <div key={field}>
                              <Code>{field}</Code>: {value}
                            </div>
                          ))}
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              ) : (
                'No users were updated.'
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ) : null}
    </section>
  );
}
