import { StyledQuantityExtract } from './AppExtracts.styled';
import { IconCheck } from '@tabler/icons-react';

const QuantityExtract = () => {
  return (
    <StyledQuantityExtract aria-hidden={true}>
      <div className="Input">
        <div className="container">
          <span className="label-text">Quantity</span>
          <div className="input">
            1
            <IconCheck width={16} color="#529f95" />
          </div>
          <span className="description">Maximum allowed quantity under the PBS is 1</span>
        </div>
      </div>

      <div className="Input repeatsInput">
        <div className="container">
          <span className="label-text">Repeats</span>
          <div className="input">
            5
            <IconCheck width={16} color="#529f95" />
          </div>
          <span className="description">Maximum allowed repeats under the PBS is 5</span>
        </div>
      </div>
    </StyledQuantityExtract>
  );
};

export default QuantityExtract;
