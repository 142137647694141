import { useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import usePagination from '../../hooks/usePagination';
import { PracticeDocumentWithId } from '../../types/firestore';
import toast from 'react-hot-toast';
import { Button, Pagination } from '@mantine/core';
import { logError } from '../../utils/logError';
import { useDisclosure } from '@mantine/hooks';
import DeletePracticeModal from './DeletePracticeModal';

type PracticesTableProps = {
  rowsPerPage: number;
  data: PracticeDocumentWithId[];
};

const PracticesTable = ({ data, rowsPerPage }: PracticesTableProps) => {
  const { currentItems, setItemsForCurrentPage, pageCount } = usePagination<PracticeDocumentWithId>(
    data.sort((a, b) => a.practiceName.localeCompare(b.practiceName)),
    rowsPerPage,
  );

  const [isDeleteModalVisible, { open: openDeleteModal, close: closeDeleteModal }] =
    useDisclosure(false);
  const [isPending, setIsPending] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState({
    practiceName: '',
    location: '',
    id: '',
  });

  // Update both the UI checkboxes and backend to ensure only one practice can be set default at any one time
  const setAsDefault = async (currentPractices: PracticeDocumentWithId[], provID: string) => {
    let prevDefault = null;
    setIsPending(true);

    try {
      // Remove the current default and record which practice this was
      for (let i = 0; i < currentPractices.length; i++) {
        // Identify the current default practice
        if (currentPractices[i].default) {
          prevDefault = currentPractices[i].id;
          // In any case, remove the current default user
          await updateDoc(doc(db, 'practices', currentPractices[i].id), {
            default: false,
          });
          break;
        }
      }

      for (let i = 0; i < currentPractices.length; i++) {
        // When reaching the practice that the user click on
        if (currentPractices[i].id === provID) {
          // Check that this is not the previous default
          if (provID !== prevDefault) {
            // And update defaults if so, ending the loop here
            await updateDoc(doc(db, 'practices', currentPractices[i].id), {
              default: true,
            });
            break;
          } else {
            // Do not reset any defaults and end the loop here
            break;
          }
        }
      }
      setIsPending(false);
      toast.success('Favourite practice updated');
    } catch (error) {
      setIsPending(false);
      toast.error('An error occurred while updating practices. Please try again.');
      logError(error);
    }
  };

  const deletePractice = async (provID: string) => {
    try {
      await deleteDoc(doc(db, 'practices', provID));
      closeDeleteModal();
      toast.success('Practice has been removed');
    } catch (error) {
      closeDeleteModal();
      toast.error('An error occurred while deleting the practice');
      logError(error);
    }
  };

  return (
    <>
      <table className="table data-table">
        <thead className="tableRowHeader">
          <tr role="row">
            <th role="columnheader" className="tableHeader" scope="col">
              Name
            </th>
            <th role="columnheader" className="tableHeader" scope="col">
              Location
            </th>
            <th role="columnheader" className="tableHeader actions-header" scope="col">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {currentItems.map((practice) => (
            <tr className="tableRowItems" key={practice.id}>
              <td data-title="Name" className="tableCell">
                {practice.practiceName}
              </td>
              <td data-title="Location" className="tableCell">
                {`${practice.streetAddress}, ${practice.suburb}`}
              </td>

              <td data-title="Actions" className="tableCell actions-cell">
                <div className="btns">
                  <div className="non-default">
                    <Button
                      component={Link}
                      to={`/edit-practice/${practice.id}`}
                      state={{ ...practice }}
                      variant="outline"
                      color="teal"
                      size="xs"
                    >
                      Edit
                    </Button>

                    <Button
                      variant="outline"
                      color="red"
                      size="xs"
                      onClick={() => {
                        openDeleteModal();
                        setSelectedPractice({
                          practiceName: practice.practiceName,
                          location: `${practice.streetAddress}, ${practice.suburb}`,
                          id: practice.id,
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                  <Button
                    variant={practice.default && !isPending ? 'filled' : 'outline'}
                    color="paleBlue"
                    size="xs"
                    className="default-btn"
                    onClick={() => setAsDefault(data, practice.id)}
                  >
                    {isPending
                      ? 'Updating...'
                      : `${practice.default ? 'Remove favourite' : 'Set as favourite'}`}
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <Pagination total={pageCount} onChange={setItemsForCurrentPage} size="sm" />
      </div>

      <DeletePracticeModal
        isOpen={isDeleteModalVisible}
        onClose={closeDeleteModal}
        onDelete={() => deletePractice(selectedPractice.id)}
        practice={{
          name: selectedPractice.practiceName,
          location: selectedPractice.location,
        }}
      />
    </>
  );
};

export default PracticesTable;
