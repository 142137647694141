import { useState, useEffect, useRef } from 'react';
import { StyledRxForm } from './RxForm.styled';
import Fieldset from '../utils/Fieldset/Fieldset';
import { useLocation } from 'react-router';
import { useNumbers } from '../../hooks/useNumbers';
import { usePBSFetch } from '../../hooks/usePBSFetch';
import { Link } from 'react-router-dom';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import PracticeDetails from '../PracticeDetails/PracticeDetails';
import PatientDetails from '../PatientDetails/PatientDetails';
import {} from '../../hooks/useHandleLEMI';
import MedicationDetails from '../MedicationDetails/MedicationDetails';
import AuthorityDetails from '../AuthorityDetails/AuthorityDetails';
import { Helmet } from 'react-helmet-async';
import { PbsData, PrescriptionFormValues, RxData } from '../../types/firestore';
import { AlertState } from '../utils/Alert/Alert';
import type { User } from 'firebase/auth';
import { Anchor, Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useUserDocument } from '../../hooks/useUserDocument';
import { PrescriberDetails } from '../PrescriberDetails/PrescriberDetails';

// Multiple items are not permitted to be prescribed on the same form; each must use an individual form (applies to optometrists only)
type RxFormProps = {
  googleLoaded: boolean;
  handleSubmit: (pbsData: PbsData | null) => void;
  existingData: RxData | null;
  user: User;
  form: UseFormReturnType<PrescriptionFormValues>;
};

const RxForm = ({ handleSubmit, googleLoaded, existingData, user, form }: RxFormProps) => {
  // Location state is only provided if generating a new Rx. This signals certain functions to run (i.e. fetch numbers)
  const { state } = useLocation();
  const { scriptNo, authRxNo, numbersLoading, fetchNumbers } = useNumbers();
  const { pbsInfo, fetchDrug, setPbsInfo } = usePBSFetch(
    existingData ? existingData.pbsData : null,
  );

  const { data: userDocument } = useUserDocument(user.uid);

  // Avoid potentially re-attempting number fetching/transactions on component re-renders
  const hasFetchedNumbers = useRef(false);

  const { setFieldValue, reset: resetForm, values: formValues } = form;

  const [numbersLoaded, setNumbersLoaded] = useState(false);
  const [drugAlerts, setDrugAlerts] = useState<AlertState>({
    name: null,
    pbsRx: null,
    maxQuantity: null,
    maxRepeats: null,
    activeIngredient: null,
    authRequired: null,
  });

  const [miscAlerts, setMiscAlerts] = useState<AlertState>({
    authCode: null,
  });

  // --- FORM INITIALISATION FUNCTIONS ---

  // Generate the unique script and authRx numbers, and attach them to the local RxForm state. This is only performed when loading the RxForm component using 'Create new prescription' btn
  useEffect(() => {
    const expander: HTMLButtonElement | null = document.querySelector('.drug-expand');
    try {
      if (state.newRx && !hasFetchedNumbers.current) {
        // Use .then() to ensure the above scriptNo and authRxNo variables are set prior to attempting to set data state with them
        fetchNumbers().then(() => {
          setNumbersLoaded((prevData) => (prevData ? prevData : !prevData));
        });

        // Reset the form using Mantine's `reset` method
        resetForm();
        hasFetchedNumbers.current = true;
      }
      // If the user has clicked a prescribe or re-prescribe button to get here then newRx will still be true, but this additional logic must be run to initialise drug data
      if (state.rePrescribe) {
        // State will have scriptData attached. Initialise form with this data.
        setFieldValue('drugData.activeIngredient', state.scriptData.activeIngredient);
        setFieldValue('drugData.brandName', state.scriptData.brandName);
        setFieldValue('drugData.quantity', state.scriptData.quantity);
        setFieldValue('drugData.repeats', state.scriptData.repeats);
        setFieldValue('drugData.dosage', state.scriptData.dosage);
        setFieldValue('drugData.itemCode', state.scriptData.itemCode);
        setFieldValue('drugData.substitutePermitted', state.scriptData.substitutePermitted);
        setFieldValue('drugData.brandOnly', state.scriptData.brandOnly);
        setFieldValue('drugData.includeBrand', state.scriptData.includeBrand);
        setFieldValue('drugData.pbsRx', state.scriptData.pbsRx);
        setFieldValue('drugData.compounded', state.scriptData.compounded);
        setFieldValue('drugData.verified', state.scriptData.verified);

        // Leave the verified status intact from the original script. Re-call the fetchDrug function; thereby updating all the authority, maxQuantity/repeats, PBS indications, and other related PBS/LEMI features

        // It is possible the drug data will be different from when the drug was initially prescribed. This will update when the fetch call is made, but some of the original script parameters (e.g. max quantity, repeats, or LEMI) may be inappropriate, and the user will not be aware. Consider a modal for scripts older than X months warning the user, or even manually add later if PBS undergoes major changes down the line
        fetchDrug(state.scriptData.itemCode);

        // Finally, expand the medication details section
        expander?.click();
      }
    } catch (error) {
      // If the Rx is not newly generated, a reference error will be thrown, where state is null. This occurs when the user clicks the make changes button. Expand the drug select in this case
      if (document.querySelector('.DrugAutocomplete')?.classList.contains('collapsed')) {
        expander?.click();
      }
    }
  }, [state, fetchNumbers, fetchDrug, setFieldValue, resetForm]);

  // Set the prescriber data section of the form. There might be a better solution for this - e.g.
  // setting it elsewhere, but for now this works well
  useEffect(() => {
    if (userDocument) {
      setFieldValue('prescriberData.fullName', userDocument.fullName);
      setFieldValue('prescriberData.prescriberNumber', userDocument.prescriberNumber);
      setFieldValue('prescriberData.qualifications', userDocument.qualifications);
    }
  }, [setFieldValue, userDocument]);

  // Set local state with authRxNo and scriptNo fetched from firestore. Activates only when the numbers have been fetched, which is performed as part of generating a newRx
  useEffect(() => {
    if (numbersLoaded) {
      setFieldValue('miscData.scriptID', scriptNo);
      setFieldValue('miscData.authRxNumber', authRxNo);
    }
  }, [numbersLoaded, authRxNo, scriptNo, setFieldValue]);

  // --- PBS FUNCTIONS ---

  // Remove all relevant PBS and verified-dependent information when there is loss of verified status (i.e. user manually adjusts active ingredient or brand name field)
  useEffect(() => {
    const clearPbsInfo = () => {
      setFieldValue('drugData.authRequired', false);
      setFieldValue('drugData.indications', '');
      setFieldValue('drugData.maxQuantity', '');
      setFieldValue('drugData.maxRepeats', '');
      setFieldValue('miscData.authCode', '');
    };

    // Toggle any PBS-related functionality if there is a change in verified status.
    if (!formValues.drugData.verified) {
      clearPbsInfo();
      setPbsInfo(null);
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        authRequired: {
          message: 'This prescription does not require authority',
          kind: 'neutral',
        },
        pbsRx: {
          message: 'Select a medication from the dropdown list for PBS information',
          kind: 'neutral',
        },
      }));
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        maxQuantity: null,
        maxRepeats: null,
      }));

      // Only bother with an authority message to select a dropdown medication IF the user is trying to prescribe on PBS
      if (formValues.drugData.pbsRx) {
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'Select a medication from the dropdown list for authority information',
            kind: 'neutral',
          },
        }));
      }
    } else {
      if (!pbsInfo) {
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          pbsRx: {
            message: 'This item is not available on the PBS',
            kind: 'neutral',
          },
          authRequired: {
            message: 'This prescription does not require authority',
            kind: 'neutral',
          },
        }));
      }
    }
  }, [setPbsInfo, pbsInfo, setFieldValue, formValues.drugData.verified, formValues.drugData.pbsRx]);

  // Function to call relevant data handlers when PBS information is successfully fetched. A long section of code. Organised as best as practicable, but fragile. Any changes will likely have unexpected effects.
  useEffect(() => {
    const handleMaxParametersInfo = (fetchedPBSData: PbsData) => {
      // PBS info-related effects here
      if (formValues.drugData.pbsRx) {
        // All PBS drugs have restrictions on quantity and repeats; set to local state
        setFieldValue('drugData.maxRepeats', fetchedPBSData['repeats']);
        setFieldValue('drugData.maxQuantity', fetchedPBSData['mq']);

        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          maxQuantity: {
            message: `Maximum allowed quantity under the PBS is ${formValues.drugData.maxQuantity}`,
            kind: 'neutral',
          },
          maxRepeats: {
            message: `Maximum allowed repeats under the PBS is ${formValues.drugData.maxRepeats}`,
            kind: 'neutral',
          },
        }));
      } else {
        // If the above condition isn't met, it means the quantity and repeat values are gone, so no valid PBS drug exists. hence remove all alerts
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          maxQuantity: null,
          maxRepeats: null,
        }));
      }
    };

    const handleAuthorityInfo = (fetchedPBSData: PbsData) => {
      // All authority required items will have flag 'A'. Set auth status accordingly
      if (fetchedPBSData['restriction-flag'] === 'A' && formValues.drugData.pbsRx) {
        setFieldValue('drugData.authRequired', true);
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'This item requires an authority prescription',
            kind: 'neutral',
          },
          pbsRx: {
            message: 'This item is available on the PBS (authority required)',
            kind: 'neutral',
          },
        }));
        if (fetchedPBSData['streamline-code'].length > 0) {
          setMiscAlerts((prevAlerts) => ({
            ...prevAlerts,
            authCode: {
              message: 'This medication is available using the streamline code above',
              kind: 'success',
            },
          }));
          setFieldValue('miscData.authCode', fetchedPBSData['streamline-code']);
        } else {
          setMiscAlerts((prevAlerts) => ({
            ...prevAlerts,
            authCode: {
              message: (
                <span>
                  This medication requires an authority code, which can be obtained through{' '}
                  <Anchor
                    href="https://proda.humanservices.gov.au/"
                    target="_blank"
                    rel="noreferrer"
                    className="proda-link"
                  >
                    PRODA
                  </Anchor>
                </span>
              ),
              kind: 'neutral',
            },
          }));
        }
      } else {
        setFieldValue('drugData.authRequired', false);
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'This prescription does not require authority',
            kind: 'neutral',
          },
        }));
      }
    };

    const handleRestrictionInfo = (fetchedPBSData: PbsData) => {
      // Check for restricted status
      switch (fetchedPBSData['restriction-flag']) {
        case 'R':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This is item is available on the PBS (restrictions apply)',
              kind: 'neutral',
            },
          }));
          // Add the indication to the local drugData state to allow certain conditional renders
          setFieldValue('drugData.indications', fetchedPBSData.indications.description ?? '');
          break;

        case 'U':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This is item is available on the PBS (unrestricted)',
              kind: 'neutral',
            },
          }));
          // Remove the indication to the local drugData state to allow certain conditional renders
          setFieldValue('drugData.indications', '');
          break;

        // All 'A' class items are also restricted with indications or Treatment criteria
        case 'A':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This item is available on the PBS (authority required)',
              kind: 'neutral',
            },
          }));
          setFieldValue('drugData.indications', fetchedPBSData.indications.description ?? '');
          break;

        default:
          break;
      }
    };

    if (pbsInfo) {
      handleRestrictionInfo(pbsInfo);
      handleAuthorityInfo(pbsInfo);
      handleMaxParametersInfo(pbsInfo);
    }
  }, [
    pbsInfo,
    setFieldValue,
    formValues.drugData.pbsRx,
    formValues.drugData.maxQuantity,
    formValues.drugData.maxRepeats,
  ]);

  return (
    <>
      <Helmet>
        <title>New prescription · OptomRx</title>
        <meta
          name="description"
          content="Write a new prescription, with a suite of tools to streamline the process."
        />
        <link rel="canonical" href="/prescription/new" />
      </Helmet>
      <ContentContainer>
        <PageHeader
          title="New prescription"
          description="Complete all sections required for your prescription"
          rightSection={<PrescriberDetails userDocument={userDocument} />}
        />

        <StyledRxForm
          className="rxform"
          onSubmit={form.onSubmit((_, event) => {
            event?.preventDefault();
            handleSubmit(pbsInfo);
          })}
          autoComplete="off"
          noValidate
        >
          <div className="scriptNo" data-testid="scriptNo">
            Script number: {numbersLoading ? 'Loading...' : form.values.miscData.scriptID}
          </div>

          <Fieldset className="prescriber-form select-fieldset" legend="Practice details">
            <PracticeDetails form={form} user={user} />
          </Fieldset>

          <Fieldset className="patient-form" legend="Patient details">
            <PatientDetails googleLoaded={googleLoaded} form={form} />
          </Fieldset>

          <Fieldset className="drug-form" legend="Medication details">
            <MedicationDetails
              alerts={drugAlerts}
              setAlerts={setDrugAlerts}
              fetchDrug={fetchDrug}
              form={form}
              isIndicationsDefaultExpanded={
                userDocument?.settings?.isIndicationsDefaultExpanded ?? true
              }
            />
          </Fieldset>

          <Fieldset className="misc-form" legend="Authority details">
            <AuthorityDetails
              drugAlerts={drugAlerts}
              miscAlerts={miscAlerts}
              numbersLoading={numbersLoading}
              form={form}
            />
          </Fieldset>

          <div className="PrescriberForm__btns">
            <Button type="submit" className="submit-btn button" variant="primary" size="md">
              Generate prescription
            </Button>
            <Button
              component={Link}
              to="/dashboard"
              variant="grey"
              className="cancel-btn"
              size="md"
            >
              Cancel
            </Button>
          </div>
        </StyledRxForm>
      </ContentContainer>
    </>
  );
};

export default RxForm;
