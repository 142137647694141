import { z } from 'zod';

export type DrugData = {
  activeIngredient: string;
  brandName: string;
  quantity: string;
  repeats: string;
  dosage: string;
  itemCode: string;
  /**
   * Indicates if brand substitution is permitted
   */
  substitutePermitted: boolean;
  /**
   * Indicates whether the Rx should list brand name only (only permitted for certain drugs)
   */
  brandOnly: boolean;
  /**
   * Indicates whether brand name should be included on the Rx
   */
  includeBrand: boolean;
  /**
   * Indicates whether this is a PBS prescription
   */
  pbsRx: boolean;
  /**
   * Whether the prescription requires compounding
   */
  compounded: boolean;
  /**
   * Set to true when the user selects an autocomplete option. Set to false on any subsequent modification of drug information, as this cannot be verified on the PBS. Only those verified drugs should be integrated with PBS backend
   */
  verified: boolean;
  /**
   * Indications for the use of drug under PBS restriction
   */
  indications: string;
  authRequired: boolean;
  maxQuantity: string;
  maxRepeats: string;
};

export type PatientData = {
  fullName: string;
  medicareNumber: string;
  medicareRefNumber: string;
} & Address;

// FIXME: move all the user-specific stuff into prescriber or user document please!
// Keep this strictly practice details. User ID probably necessary to link back to user, but ideally
// we move all a user's practices into a practices array on the User document
export type PracticeData = {
  default: boolean;
  fullName: string;
  phoneNumber: string;
  practiceName: string;
  prefix: boolean;
  prescriberNumber: string;
  qualifications: string;
  userId: string;
} & Address;

export type MiscData = {
  /**
   * The date the Rx was written (can be manually modified)
   */
  date: string;
  /**
   * Auto-generated
   */
  authRxNumber: string;
  /**
   * Either a streamline authority code, or a code obtained via telephone or online approval
   */
  authCode: string;
  /**
   * Unique identifier for every prescription
   */
  scriptID: string;
  /**
   * The reason why the drug was prescribed (authority scripts only)
   */
  justification: string;
  /**
   * Whether the patient has received prior authority for this medication (authority scripts only)
   */
  prevAuth: false;
  /**
   * Age of the patient (authority scripts only)
   */
  age: string;
};

export const pbsDataSchema = z.object({
  'program-code': z.string(),
  'atc-level-code': z.string(),
  'atc-type': z.string(),
  'atc-print-option': z.string(),
  'item-code': z.string(),
  'restriction-flag': z.string(),
  'has-caution': z.string(),
  'has-note': z.string(),
  mq: z.string(),
  repeats: z.string(),
  'manufacturer-code': z.string(),
  'pack-size': z.string(),
  'markup-band': z.string(),
  'fee-code': z.string(),
  'dangerous-drug-code': z.string(),
  'brand-premium': z.string(),
  'therapeutic-premium': z.string(),
  cp2p: z.string(),
  cdpmq: z.string(),
  lp2p: z.string(),
  ldpmq: z.string(),
  mp2p: z.string(),
  mdpmq: z.string(),
  mrvsn: z.string(),
  bioequivalence: z.string(),
  'brand-name': z.array(z.string()),
  'mp-pt': z.string(),
  'tpuu-or-mpp-pt': z.string(),
  'indication-id': z.string(),
  'increase-code': z.string(),
  'note-ids': z.array(z.string()),
  caution_ids: z.array(z.string()),
  indications: z
    .object({
      description: z.string(),
      'misc-res-code': z.string(),
      'date-req': z.string(),
      'text-req': z.string(),
    })
    .partial(),
  notes: z.array(z.string()),
  cautions: z.array(z.string()),
  'streamline-code': z.string(),
  atc: z.string(),
  'caution-ids': z.array(z.string()),
  lmbc: z.boolean(),
  lemi: z.boolean(),
});

/**
 * JSON data shape for PBS medication entries directly downloaded from the PBS developers page.
 */
export type PbsData = z.infer<typeof pbsDataSchema>;

/**
 * A bare bones Rx data set used to initialise the Rx form
 */
export type InitialRxData = {
  drugData: {
    substitutePermitted: boolean;
    brandOnly: boolean;
    includeBrand: boolean;
    pbsRx: boolean;
    compounded: boolean;
    authRequired: boolean;
  };
  patientData: object;
  prescriberData: object;
  miscData: {
    date: string;
  };
  pbsData: null;
};

/**
 * Represents a 'complete' prescription data set separated into individual 'sections'
 */
export type RxData = {
  pbsData: PbsData | null;
};

/**
 * Represents an abridged version of the full PBS drug entry with only the information required
 * for use in an autocomplete option.
 */
export type DrugAutocompleteOption =
  | {
      'item-code': string;
      // FIXME: Consider making this a string rather than array of single string
      'brand-name': string[];
      'mp-pt': string;
      'tpuu-or-mpp-pt': string;
      // FIXME: Not actually yet included in any drug autocomplete options, need to include on PBS pipeline
      compounded?: boolean;
      lemi: boolean;
      lmbc: boolean;
    }
  | {
      'item-code': string;
      // FIXME: Consider making this a string rather than array of single string
      'brand-name': string[];
      'mp-pt': string;
      'tpuu-or-mpp-pt': string;
      // FIXME: Not actually yet included in any drug autocomplete options, need to include on PBS pipeline
      compounded?: boolean;
      lemi?: boolean;
      lmbc?: boolean;
    };

/**
 * A comprehensive list of all possible route pathnames in the app
 */
export type PathName =
  | '/'
  | '/privacy-policy'
  | '/terms-of-service'
  | '/features'
  | '/faq'
  | '/about'
  | '/dashboard'
  | '/settings'
  | '/prescription/new'
  | '/prescription/review'
  | '/signup'
  | '/login'
  | `/edit-practice/${string | number}`
  | '/add-practice'
  | '/practices'
  | '/scripts'
  | `/scripts/${string | number}`
  | '/reset-password'
  | '/admin';

export const numberDocumentSchema = z.object({
  current: z.string(),
});

/**
 * Represents the Firestore document type for `numbers` documents
 *
 * All currently defined documents in the `numbers` collection:
 * - `authRxNo`
 * - `scriptNo`
 */
export type NumberDocument = z.infer<typeof numberDocumentSchema>;

// NOTE: This will eventually be removed completely
export type LegacyPrescriberDocument = {
  default: boolean;
  fullName: string;
  phoneNumber: string;
  practiceName: string;
  prefix: boolean;
  prescriberNumber: string;
  qualifications: string;
  uid: string;
} & Address;

// SCHEMAS

export const addressSchema = z.object({
  streetAddress: z.string(),
  subpremise: z.string(),
  suburb: z.string(),
  postcode: z.string(),
  state: z.string(),
});

export const prescriberSchema = z
  .object({
    default: z.boolean(),
    fullName: z.string(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    prescriberNumber: z.string(),
    qualifications: z.string(),
    uid: z.string(),
  })
  .merge(addressSchema);

export const prescriptionSchema = z.object({
  activeIngredient: z.string(),
  age: z.string().optional(),
  authCode: z.string(),
  authRequired: z.boolean(),
  authRxNumber: z.string(),
  brandName: z.string(),
  brandOnly: z.boolean(),
  compounded: z.boolean(),
  date: z.string(),
  dateTime: z.string().optional(),
  dosage: z.string(),
  /**
   * Optional for scripts in a user's "favourites" and "scripts" lists,
   * but not for a prescription document
   */
  favourite: z.boolean().optional(),
  includeBrand: z.boolean(),
  indications: z.string(),
  itemCode: z.string(),
  justification: z.string().optional(),
  maxQuantity: z.string(),
  maxRepeats: z.string(),
  pbsRx: z.boolean(),
  prevAuth: z.boolean().optional(),
  quantity: z.string(),
  repeats: z.string(),
  scriptID: z.string(),
  substitutePermitted: z.boolean(),
  verified: z.boolean(),
  customName: z.string().optional(),
});

export const practiceDocumentSchema = z
  .object({
    default: z.boolean(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    userId: z.string(),
  })
  .merge(addressSchema);

export const practiceDocumentWithIdSchema = practiceDocumentSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export const enrichedPracticeSchema = z
  .object({
    default: z.boolean(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    userId: z.string(),
    fullName: z.string(),
    prescriberNumber: z.string(),
    qualifications: z.string(),
  })
  .merge(addressSchema);

export const prescriptionDocumentSchema = prescriptionSchema.merge(
  z.object({
    /**
     * Getting a prescription document directly will guarantee the `favourite` property
     */
    favourite: z.boolean(),
    // Scripts written on an earlier version of OptomRx will not have a prescriber associated with
    // them. Prescriber started being added to scripts mid-2024.
    prescriber: prescriberSchema.optional(),
  }),
);

export const prescriptionDocumentWithIdSchema = prescriptionSchema.merge(
  z.object({
    /**
     * Getting a prescription document directly will guarantee the `favourite` property
     */
    favourite: z.boolean(),
    id: z.string(),
  }),
);

export const userSettingsSchema = z.object({
  isIndicationsDefaultExpanded: z.boolean(),
});

export const userDocumentSchema = z.object({
  favourites: z.array(
    prescriptionSchema.omit({
      favourite: true,
    }),
  ),
  scripts: z.array(
    prescriptionSchema.omit({
      favourite: true,
    }),
  ),
  // Qualifications is optional no matter how you spin it, and must be allowed as such
  fullName: z.string(),
  prescriberNumber: z.string(),
  qualifications: z.string(),
  settings: userSettingsSchema,
  isAdmin: z.boolean().optional(),
  // FIXME: Use a more meaningful notification system than this. Also remove these from all docs
  // once they're no longer used
  hideBanner: z.boolean().optional(),
  hideBetaModal: z.boolean().optional(),
  /** The date on which this user was notified (by email) of suspected fraudulent activity */
  fraudulentWarningDate: z.string().optional(),
});

export const userDocumentWithIdSchema = userDocumentSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export type Address = z.infer<typeof addressSchema>;
export type Prescriber = z.infer<typeof prescriberSchema>;
export type Prescription = z.infer<typeof prescriptionSchema>;

/**
 * Raw Firestore prescription document
 */
export type PrescriptionDocument = z.infer<typeof prescriptionDocumentSchema>;

/**
 * Raw Firestore prescription document with ID appended
 */
export type PrescriptionDocumentWithId = z.infer<typeof prescriptionDocumentWithIdSchema>;

/**
 * Raw Firestore user document
 */
export type UserDocument = z.infer<typeof userDocumentSchema>;

/**
 * Firestore user document with document ID
 */
export type UserDocumentWithId = z.infer<typeof userDocumentWithIdSchema>;

/**
 * Firestore practice document
 */
export type PracticeDocument = z.infer<typeof practiceDocumentSchema>;

/**
 * Firestore practice document with document ID
 */
export type PracticeDocumentWithId = z.infer<typeof practiceDocumentWithIdSchema>;

/**
 * Form values for the `PrescriberForm`
 */
export type PracticeFormValues = Omit<
  PracticeData,
  'default' | 'userId' | 'fullName' | 'prescriberNumber' | 'qualifications'
>;

export type PrescriberData = {
  fullName: string;
  prescriberNumber: string;
  qualifications: string;
};

export type PrescriptionFormValues = {
  drugData: DrugData;
  patientData: PatientData;
  practiceData: PracticeDocument;
  miscData: MiscData;
  prescriberData: PrescriberData;
};
