import { useEffect } from 'react';
import { ZodError } from 'zod';
import { FirestoreError } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { logError } from '../utils/logError';

/**
 * General use fetch error hook that provides generalised error messages to users after attempting
 * GET fetch operations.
 */
export const useHandleFetchError = (error: unknown) => {
  useEffect(() => {
    if (!error) return;
    logError(error);

    if (error instanceof Error) {
      switch (true) {
        case error instanceof ZodError:
          toast.error(
            'There was a problem processing the data. Please refresh or try again later.',
          );
          break;

        case error instanceof FirestoreError:
          toast.error('A database error occurred. Please try again later.');
          break;

        default:
          // All errors reaching this point should be of generic type `Error`
          toast.error('An unexpected error occurred. Please try again.');
          break;
      }
    }
  }, [error]);
};
