import { styled } from 'styled-components';

const StyledViewScript = styled.div`
  position: relative;

  .PrescriberForm__btns {
    margin: 2rem 0 0rem 0;
    display: flex;
    align-items: center;
    width: 100%;

    a {
      margin-right: 1.5rem;
    }
  }

  .Script__info {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .Script__title {
      background-color: var(--mantine-color-gray-1);
      padding: 0.75rem 0.75rem;
      font-size: 1.2rem;
      font-family: var(--font-stack-segoe);
      font-weight: 400;
      color: #48515b;
      border-top: none;
    }

    .Script__info--section {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
      color: #48515b;
    }

    .Script__medication,
    .Script__pbs {
      padding-bottom: 0.5rem;
    }
  }

  .Modal {
    .form-field {
      width: 100%;
      margin-bottom: 0.4rem;

      label {
        font-size: 1rem;
      }
    }

    .Modal__buttons {
      padding-top: 2rem;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      .cancel {
        margin-right: 1rem;
      }
    }
  }

  /* Portrait phones */
  @media (max-width: 470px) {
    .PrescriberForm__btns {
      flex-direction: column;

      button,
      a {
        width: 90%;
        min-width: 80px;
      }

      a {
        margin: 0 0 1.5rem 0;
      }
    }
  }
`;

export { StyledViewScript };
