import { Button, TextInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Prescription, PrescriptionDocument } from '../../types/firestore';
import { updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { logError } from '../../utils/logError';
import type { User } from 'firebase/auth';
import baseModalClasses from '../../styles/Modal.module.css';

type AddFavouriteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  prescription: PrescriptionDocument;
};

type FormValues = {
  customName: string;
};

export const AddFavouriteModal = ({
  isOpen,
  onClose,
  user,
  prescription,
}: AddFavouriteModalProps) => {
  const [isSavingFavourite, setIsSavingFavourite] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      customName: '',
    },
  });

  // Takes current script and adds it to the user's favourites array
  const addToFavourites = async (scriptToAdd: PrescriptionDocument, customName: string) => {
    // Ensure the scriptData is not null
    if (scriptToAdd) {
      setIsSavingFavourite(true);
      try {
        // Add script data to the current user's favourite scripts. This operation can be called multiple times as arrayUnion will not add elements already present BUT the UI design should encourage multiple calls
        const docToAdd: Prescription = {
          ...scriptToAdd,
          customName: customName,
          favourite: true,
        };

        await updateDoc(doc(db, 'users', user.uid), {
          favourites: arrayUnion(docToAdd),
        });
        setIsSavingFavourite(false);
        onClose();
        toast.success('Prescription saved to favourites');
      } catch (error) {
        setIsSavingFavourite(false);
        onClose();
        toast.error('An error occurred while adding favourites. Please try again.');
        logError(error);
      }
    }
  };

  return (
    <Modal title="Add to favourites" onClose={onClose} opened={isOpen}>
      <form
        onSubmit={form.onSubmit((values, event) => {
          event?.preventDefault();
          addToFavourites(prescription, values.customName);
        })}
      >
        <TextInput
          label="Prescription name (optional)"
          {...form.getInputProps('customName')}
          description="This script will be displayed in your favourites list using this name."
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          placeholder={prescription.activeIngredient}
        />

        <div className={baseModalClasses.buttons}>
          <Button
            className="cancel"
            variant="grey"
            onClick={() => {
              form.reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" loading={isSavingFavourite}>
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};
