import { sendEmailVerification } from 'firebase/auth';
import type { User } from 'firebase/auth';
import toast from 'react-hot-toast';
import { Button } from '@mantine/core';
import settingsClasses from './Settings.module.css';
import { logError } from '../../utils/logError';
import { VerifyEmailModal } from '../Dashboard/VerifyEmailModal';
import { useDisclosure } from '@mantine/hooks';
import { ChangeEmailModal } from './ChangeEmailModal';

type ChangeEmailProps = {
  verified: boolean;
  user: User;
};

const ChangeEmail = ({ verified, user }: ChangeEmailProps) => {
  const [isVerifyModalVisible, { open: openVerifyModal, close: closeVerifyModal }] =
    useDisclosure(false);
  const [isChangeEmailModalVisible, { open: openChangeEmailModal, close: closeChangeEmailModal }] =
    useDisclosure(false);

  // Used for manual user-initiated resending of verification email
  const resendEmailVerification = async () => {
    try {
      await sendEmailVerification(user);
      toast.success('A verification email has been sent to your email address');
    } catch (error) {
      toast.error('An error occurred while trying to send the email. Please try again.');
      logError(error);
    }
  };

  return (
    <>
      <div className="change-email">
        <div className="form-title">Change email</div>
        {verified ? null : (
          <p className="no-email-desc">
            Please verify your email address to access all account settings, receive notifications,
            and reset your password
          </p>
        )}
        <div className="current-email">{user.email}</div>
        <div className="email-group">
          {verified ? (
            <div className="verified">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="alert-icon alert-icon--success"
                viewBox="0 0 512 512"
                width="17px"
              >
                <path
                  d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                  fill="none"
                  stroke="#096600"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                />
                <path
                  fill="none"
                  stroke="#096600"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M352 176L217.6 336 160 272"
                />
              </svg>
              <span>Verified</span>
            </div>
          ) : (
            <div className="unverified">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="alert-icon alert-icon--neutral"
                viewBox="0 0 512 512"
                width="17px"
              >
                <path
                  d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                  fill="none"
                  stroke="#9a6700"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                />
                <path
                  d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                  fill="none"
                  stroke="#9a6700"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                />
                <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z" fill="#9a6700" />
              </svg>
              <span>Unverified</span>
            </div>
          )}
          {verified ? null : (
            <Button
              variant="transparent"
              className="resend"
              onClick={resendEmailVerification}
              size="compact-sm"
            >
              Resend verification email
            </Button>
          )}
        </div>

        <Button
          variant="primary"
          onClick={openChangeEmailModal}
          classNames={{ root: settingsClasses.updateEmailButton }}
        >
          Update email
        </Button>
      </div>

      <ChangeEmailModal
        isOpen={isChangeEmailModalVisible}
        onClose={closeChangeEmailModal}
        user={user}
        openVerifyModal={openVerifyModal}
      />
      <VerifyEmailModal isOpen={isVerifyModalVisible} onClose={closeVerifyModal} />
    </>
  );
};

export default ChangeEmail;
