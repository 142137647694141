import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';

/**
 * Common 'Sign up to OptomRx' button variant used several times on the landing page
 */
export const SignupButton = () => {
  return (
    <Button component={Link} to="/signup" variant="primary" size="lg">
      Sign up to OptomRx
    </Button>
  );
};

export default SignupButton;
