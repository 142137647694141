import { StyledIndicationsExtract } from './AppExtracts.styled';
import { Checkbox } from '@mantine/core';

const IndicationsExtract = () => {
  return (
    <StyledIndicationsExtract aria-hidden={true}>
      <Checkbox
        className="pbs-checkbox"
        label="PBS prescription"
        description="This item is available on the PBS (authority required)"
        defaultChecked
      />

      <div className="Indications">
        <div className="Indications__btn collapsible">Restricted benefit:</div>
        <div className="Indications__content expand">
          <div className="Indication">
            <div className="Indication__main">Severe dry eye syndrome</div>
            <div className="Indication__extra">
              <div className="Indication__clinical">Clinical criteria:</div>
              <ul className="Indication__list">
                <li className="Indication__list-item">
                  Patient must be sensitive to preservatives in multi-dose eye drops
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </StyledIndicationsExtract>
  );
};

export default IndicationsExtract;
