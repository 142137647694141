import { Helmet } from 'react-helmet-async';
import { SettingsPage } from './Profile';
import Subheading from '../utils/Subheading/Subheading';

export const AdminSettings = () => {
  return (
    <>
      <Helmet>
        <title>Account · OptomRx</title>
        <meta name="admin" content="Manage your admin-only settings." />
        <link rel="canonical" href="/settings/admin" />
      </Helmet>
      <SettingsPage>
        <Subheading text="Admin" />
        <div>Nothing here yet.</div>
      </SettingsPage>
    </>
  );
};
