import { StyledPracticesExtract } from './AppExtracts.styled';
import PageHeader from '../../utils/PageHeader/PageHeader';
import { Button } from '@mantine/core';

const PracticesExtract = () => {
  return (
    <StyledPracticesExtract aria-hidden={true}>
      <div className="sc-hGPBjI cwdyBG Practices">
        <PageHeader
          title="Practices"
          description="Add and modify practice details for your prescriptions"
        />
        <Button component="div" variant="primary" size="sm" className="get-started">
          Add new practice
        </Button>

        <div className="Practices__container">
          <table className="table data-table">
            <thead className="tableRowHeader">
              <tr>
                <th className="tableHeader" scope="col">
                  Name
                </th>
                <th className="tableHeader" scope="col">
                  Location
                </th>
                <th className="tableHeader actions-header" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="tableRowItems">
                <td data-title="Name" className="tableCell">
                  Gleaming Gaze Grotto
                </td>
                <td data-title="Location" className="tableCell">
                  9 Watson Street, Diggora
                </td>
                <td data-title="Actions" className="tableCell actions-cell">
                  <div className="btns">
                    <Button component="div" variant="outline" color="teal" size="xs">
                      Edit
                    </Button>
                    <Button component="div" variant="outline" color="red" size="xs">
                      Delete
                    </Button>
                    <Button
                      component="div"
                      variant="filled"
                      color="paleBlue"
                      size="xs"
                      className="favBtn"
                    >
                      Remove favourite
                    </Button>
                  </div>
                </td>
              </tr>
              <tr className="tableRowItems middleRow">
                <td data-title="Name" className="tableCell">
                  Spectacle Spells Eyecare
                </td>
                <td data-title="Location" className="tableCell">
                  27 Wright Street, Cooroibah
                </td>
                <td data-title="Actions" className="tableCell actions-cell">
                  <div className="btns">
                    <Button component="div" variant="outline" color="teal" size="xs">
                      Edit
                    </Button>
                    <Button component="div" variant="outline" color="red" size="xs">
                      Delete
                    </Button>
                    <Button
                      component="div"
                      variant="outline"
                      color="paleBlue"
                      size="xs"
                      className="favBtn"
                    >
                      Set as favourite
                    </Button>
                  </div>
                </td>
              </tr>
              <tr className="tableRowItems">
                <td data-title="Name" className="tableCell">
                  Twinkling Twilight Optics
                </td>
                <td data-title="Location" className="tableCell">
                  14 Taylor Street, Shepparton North
                </td>
                <td data-title="Actions" className="tableCell actions-cell">
                  <div className="btns">
                    <Button component="div" variant="outline" color="teal" size="xs">
                      Edit
                    </Button>
                    <Button component="div" variant="outline" color="red" size="xs">
                      Delete
                    </Button>
                    <Button
                      component="div"
                      variant="outline"
                      color="paleBlue"
                      size="xs"
                      className="favBtn"
                    >
                      Set as favourite
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="sc-dlVxhl ciziMa TableFooter">
            <div className="pagination-button">
              <span className="caret">{'‹'}</span>
            </div>
            <div className="pagination-button active">1</div>
            <div className="pagination-button">
              <span className="caret">{'›'}</span>
            </div>
          </div>
        </div>
      </div>
    </StyledPracticesExtract>
  );
};

export default PracticesExtract;
