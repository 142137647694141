import { Button, TextInput, Alert, PasswordInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import alertClasses from '../../styles/Alert.module.css';
import { validateRequiredInput } from '../../utils/formUtils';
import { sendEmailVerification, updateEmail, type User } from 'firebase/auth';
import baseModalClasses from '../../styles/Modal.module.css';
import { FirebaseError } from 'firebase/app';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { logError } from '../../utils/logError';
import { refreshCredentials } from './Account';
import classes from './ChangeEmailModal.module.css';

type ChangeEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  openVerifyModal: () => void;
};

type FormValues = {
  newEmail: string;
  password: string;
};

export const ChangeEmailModal = ({
  isOpen,
  onClose,
  openVerifyModal,
  user,
}: ChangeEmailModalProps) => {
  const { handleSettingsError } = useErrorHandling();

  const [changeEmailPending, setChangeEmailPending] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      newEmail: '',
      password: '',
    },

    validate: {
      newEmail: (value) => {
        if (value.trim().length === 0) {
          return 'Please enter an email';
        }
        if (value === user.email) {
          return 'New email address must be different from the current email address';
        }
        return null;
      },
      password: (value) => validateRequiredInput(value, 'Please enter a password'),
    },
  });

  // Used to handle all operations involved with email update
  const performEmailUpdate = async (newEmail: string, password: string) => {
    setChangeEmailPending(true);
    let reauthenticated = false;
    // Refresh the user's credential regardless of when they last signed in
    try {
      await refreshCredentials(user, password);
      reauthenticated = true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        setGlobalError(handleSettingsError(error.code));
      } else {
        logError(error);
        toast.error('Unable to update email. Please try again');
      }
      setChangeEmailPending(false);
    }
    // Once successfully reauthenticated, proceed with operations
    if (reauthenticated) {
      try {
        // FIXME: Switch to verifyBeforeUpdateEmail
        await updateEmail(user, newEmail);
        onClose;
        setChangeEmailPending(false);
        toast.success('Email updated successfully');

        sendEmailVerification(user);
        // Timeout is purely to not overwhelm the user with modals flying one after the other
        setTimeout(() => {
          openVerifyModal();
        }, 1000);
      } catch (error) {
        setChangeEmailPending(false);
        // Custom error handling is needed because alerts are not fixed to one input
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/invalid-email':
              setGlobalError('Please enter a valid email address.');
              break;
            case 'auth/email-already-in-use':
              setGlobalError('This email is already in use. Try another.');
              break;
            case 'auth/too-many-requests':
              setGlobalError(
                'Failed to authorise too many times. Please wait a few minutes before trying again.',
              );
              break;
            case 'auth/network-request-failed':
              setGlobalError(
                "We couldn't connect to the network. Please check your internet connection and try again.",
              );
              break;
            default:
              setGlobalError('An unknown server error occurred. Please try again.');
              break;
          }
        } else {
          logError(error);
          toast.error('Unable to update email. Please try again');
        }
      }
    }
  };

  return (
    <Modal title="Change email" onClose={onClose} opened={isOpen}>
      <div className={classes.currentEmailContainer}>
        <div className={classes.currentEmailLabel}>Current email address:</div>
        <div>{user.email}</div>
      </div>
      <form
        noValidate
        onSubmit={form.onSubmit((values, event) => {
          event?.preventDefault();
          performEmailUpdate(values.newEmail, values.password);
        })}
      >
        <TextInput
          className={classes.newEmailInput}
          label="New email address"
          {...form.getInputProps('newEmail')}
          onChange={(event) => {
            form.getInputProps('newEmail').onChange(event);
            setGlobalError(null);
          }}
        />

        <PasswordInput
          className="password-input"
          label="Password"
          autoComplete="password"
          {...form.getInputProps('password')}
          onChange={(event) => {
            form.getInputProps('password').onChange(event);
            setGlobalError(null);
          }}
        />

        {globalError ? (
          <Alert
            variant="light"
            icon={<IconInfoCircle />}
            color="red"
            className={alertClasses.errorAlert}
          >
            {globalError}
          </Alert>
        ) : null}

        <div className={baseModalClasses.buttons}>
          <Button
            variant="grey"
            className="cancel"
            onClick={() => {
              onClose();
              form.reset();
              setGlobalError(null);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" loading={changeEmailPending}>
            Change
          </Button>
        </div>
      </form>
    </Modal>
  );
};
