import { styled } from 'styled-components';

const StyledAddressAutocomplete = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  width: 26rem;
  position: relative;

  .success {
    /* TODO: Add success styles for inputs */
    input {
    }
  }

  /* Show and hide classes control the sub-fields display (i.e. fields other than autocomplete) */
  .show {
    display: flex;
  }

  .hide {
    display: none;
  }

  .address-collapse {
    flex-direction: column;
  }

  .postcode-field {
    max-width: 6rem;
  }

  .street-address {
    margin-bottom: 1rem;

    .alert-container {
      margin-top: -0.8rem;
    }
  }

  .expand-btn {
    position: absolute;
    right: 0.3rem;
    top: 5.9rem;
    padding: 0;
    margin: 0;
    height: 16px;

    &:focus {
      outline-offset: 2px;
    }
  }

  /* Roughly portrait tablets (small), and landscape phones and down */
  @media (max-width: 590px) {
    width: 100%;
    max-width: 26rem;

    #autocomplete-prescriber-alert,
    #autocomplete-patient-alert {
      width: 55%;
    }

    #postcode-alert {
      /* The limiting factor on width responsiveness */
      width: 12rem;
    }
  }

  /* Portrait phones */
  @media (max-width: 420px) {
    margin-bottom: 0.75rem;
  }
`;

export { StyledAddressAutocomplete };
