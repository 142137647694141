import { useMediaQuery } from '@mantine/hooks';
import { UserDocument } from '../../types/firestore';
import classes from './PrescriberDetails.module.css';
import { Skeleton } from '@mantine/core';

export const PrescriberDetails = ({ userDocument }: { userDocument: UserDocument | null }) => {
  const isWideScreen = useMediaQuery('(min-width: 768px)');
  if (!userDocument) {
    return (
      <div className={classes.prescriberDetailsSkeleton}>
        {isWideScreen ? (
          <>
            <Skeleton height={8} width={100} />
            <Skeleton height={8} width={100} />
            <Skeleton height={8} width={100} />
          </>
        ) : (
          <Skeleton height={8} width={200} />
        )}
      </div>
    );
  }
  // FIXME: Update this once qualifications is no longer optional (but may still be an empty string)
  const hasQualifications = userDocument.qualifications?.trim() !== '';
  return (
    <div className={classes.prescriberDetails}>
      {isWideScreen ? (
        <>
          <p>{userDocument.fullName}</p>
          {hasQualifications ? <p>{userDocument.qualifications}</p> : null}
          <p>{userDocument.prescriberNumber}</p>
        </>
      ) : (
        <p>
          {userDocument.fullName} {hasQualifications ? `| ${userDocument.qualifications}` : null} |{' '}
          {userDocument.prescriberNumber}
        </p>
      )}
    </div>
  );
};
