import { useState } from 'react';
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  updateProfile,
} from 'firebase/auth';
import { useAuthContext } from './useAuthContext';
import { db, auth } from '../firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useErrorHandling } from './useErrorHandling';
import { FirebaseError } from 'firebase/app';
import { AuthenticatedUser } from '../context/AuthContext';
import { UserDocument } from '../types/firestore';
import { logError } from '../utils/logError';

const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 500;

// Custom hook to handle user signing in
export const useSignup = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { handleLoginSignupError } = useErrorHandling();

  const signup = async (
    signupFormValues: {
      email: string;
      password: string;
      fullName: string;
      prescriberNumber: string;
      qualifications: string;
    },
    setFirstSignIn: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setError(null);
    setIsPending(true);
    const { email, password, fullName, prescriberNumber, qualifications } = signupFormValues;

    try {
      await setPersistence(auth, browserSessionPersistence);
      const res = await createUserWithEmailAndPassword(auth, email, password);

      const userDocRef = doc(db, 'users', res.user.uid);
      await setDoc(userDocRef, {
        scripts: [],
        favourites: [],
        settings: {
          isIndicationsDefaultExpanded: true,
        },
        fullName,
        prescriberNumber,
        qualifications,
      } satisfies UserDocument);

      // Retry mechanism to ensure document is persisted - it is possible that the user document
      // isn't immediately available after setDoc resolves
      let userDocument: UserDocument | null = null;
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          userDocument = userDocSnapshot.data() as UserDocument;
          break;
        }
        await new Promise((res) => setTimeout(res, RETRY_DELAY_MS));
      }

      if (!userDocument) {
        throw new Error('User document could not be verified after several attempts');
      }

      // No user will be ad admin by default on sign up
      const enrichedUser: AuthenticatedUser = Object.assign(
        Object.create(Object.getPrototypeOf(res.user)),
        res.user,
        {
          isAdmin: false,
          ...userDocument,
        },
      );

      await updateProfile(res.user, {
        displayName: fullName,
      });

      setIsPending(false);
      dispatch({ type: 'LOGIN', payload: enrichedUser });
      setFirstSignIn(true);
    } catch (err) {
      setIsPending(false);
      if (err instanceof FirebaseError) {
        const errorMsg = handleLoginSignupError(err.code);
        setError(errorMsg);
      } else {
        logError(err);
        setError('An unknown error occurred during sign up');
      }
    }
  };

  return { error, isPending, signup, setError };
};
