import { db } from '../../../firebase/config';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';
import { UserDocument } from '../../../types/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { Button, Code, Table } from '@mantine/core';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function UsersWithMissingFields() {
  const [isLoading, setIsLoading] = useState(false);
  const [usersWithMissingFields, setUsersWithMissingFields] = useState<
    {
      userId: string;
      missingFields: string[];
    }[]
  >([]);

  /**
   * Show users with any of the following missing user properties:
   * - Full Name
   * - Prescriber Number
   * - Qualifications
   *
   * NOTE: This does not check for users with empty strings for these properties, only those where
   * the properties are missing entirely.
   */
  async function showUsersWithMissingProperties() {
    const usersWithMissingFields: { userId: string; missingFields: string[] }[] = [];
    try {
      setIsLoading(true);

      const usersCollection = collection(db, 'users');
      const userQuerySnapshot = await getDocs(usersCollection);

      userQuerySnapshot.forEach((doc) => {
        const missingFields: string[] = [];
        const userData = doc.data() as UserDocument;

        for (const field of ['fullName', 'prescriberNumber', 'qualifications', 'settings']) {
          if (!(field in userData)) {
            missingFields.push(field);
          }
        }
        if (missingFields.length > 0) {
          usersWithMissingFields.push({ userId: doc.id, missingFields });
        }
      });

      setIsLoading(false);
      setUsersWithMissingFields(usersWithMissingFields);
      if (usersWithMissingFields.length === 0) {
        toast.success('No users with missing fields found.');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error scanning users collection:', error);
    }
  }

  const userRows = usersWithMissingFields.map((user) => (
    <Table.Tr key={user.userId}>
      <Table.Td>{user.userId}</Table.Td>
      <Table.Td>{user.missingFields.join(', ')}</Table.Td>
    </Table.Tr>
  ));

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Users with missing fields</h4>
      <p>
        Click this button to view all users who are missing any of the the <Code>fullName</Code>,{' '}
        <Code>prescriberNumber</Code>, <Code>settings</Code>, or <Code>qualifications</Code>{' '}
        properties in their user document.
      </p>
      <div className={migrationClasses.buttonsContainer}>
        <Button
          onClick={showUsersWithMissingProperties}
          loading={isLoading}
          className={migrationClasses.button}
        >
          Show users with missing properties
        </Button>
        <Button
          onClick={() => setUsersWithMissingFields([])}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>
      {usersWithMissingFields.length > 0 && (
        <Table className={migrationClasses.table} striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>User ID</Table.Th>
              <Table.Th>Missing fields</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{userRows}</Table.Tbody>
        </Table>
      )}
    </section>
  );
}
