import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../firebase/config';
import PracticeForm from '../PracticeForm/PracticeForm';
import { StyledEditPractice } from './EditPractice.styled';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import { PracticeFormValues, practiceDocumentWithIdSchema } from '../../types/firestore';
import type { User } from 'firebase/auth';
import toast from 'react-hot-toast';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { useUserDocument } from '../../hooks/useUserDocument';
import { PrescriberDetails } from '../PrescriberDetails/PrescriberDetails';
import { logError } from '../../utils/logError';

type EditPracticeProps = {
  googleLoaded: boolean;
  user: User;
};

const EditPractice = ({ googleLoaded, user }: EditPracticeProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [localPending, setLocalPending] = useState(false);

  // Extract the selected practice data passed via React Router state
  const { state: existingData } = useLocation();
  const initialFormValues = practiceDocumentWithIdSchema.parse(existingData);

  const { data: userDocument, error: userError } = useUserDocument(user.uid);

  useHandleFetchError(userError);

  // Used when the user submits the form - save/edit details to backend
  const handleSubmit = async (values: PracticeFormValues) => {
    setLocalPending(true);

    try {
      if (!id) {
        throw new Error('No ID found in URL parameters');
      }
      // Update data on backend
      await updateDoc(doc(db, 'practices', id), {
        ...values,
        // Default isn't really a form value, but is included in the initialFormValues object
        default: initialFormValues.default,
      });
      setLocalPending(false);
      // Inform the user the changes have been successfully applied, then return to the previous page
      toast.success('Practice details updated');
      navigate('/practices');
    } catch (error) {
      logError(error);
      setLocalPending(false);
      // Only an error toast is necessary. Specific error handling is not useful or necessary
      toast.error('An error occurred while saving changes');
    }
  };

  const cancelEdit = () => {
    navigate('/practices');
  };

  return (
    <>
      <Helmet>
        <title>Edit practice · OptomRx</title>
        <meta name="description" content="Edit practice details for the selected practice." />
      </Helmet>
      <ContentContainer>
        <StyledEditPractice>
          <PageHeader
            title="Edit practice"
            description="Practice details will appear on your prescriptions"
            rightSection={<PrescriberDetails userDocument={userDocument} />}
          />
          <div className="form-container">
            <span className="form-title">Practice details</span>
            <PracticeForm
              googleLoaded={googleLoaded}
              initialFormValues={initialFormValues}
              handleSubmit={handleSubmit}
              handleCancel={cancelEdit}
              submitBtnLabel="Save changes"
              pending={localPending}
            />
          </div>
        </StyledEditPractice>
      </ContentContainer>
    </>
  );
};

export default EditPractice;
