import { Helmet } from 'react-helmet-async';
import Subheading from '../utils/Subheading/Subheading';
import { styled } from 'styled-components';
import { Anchor } from '@mantine/core';

export const SettingsPage = styled.section`
  width: 100%;
`;

export const Help = () => {
  return (
    <>
      <Helmet>
        <title>Profile · OptomRx</title>
        <meta name="help" content="Find helpful links and learn more about OptomRx" />
        <link rel="canonical" href="/settings/help" />
      </Helmet>
      <SettingsPage>
        <Subheading text="Help" />
        <p>
          If you have any questions, please visit our{' '}
          <Anchor href="/faq" target="_blank" rel="noreferrer">
            FAQ page
          </Anchor>{' '}
          for more information.
        </p>
      </SettingsPage>
    </>
  );
};
