import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import { useUserDocumentWithId } from '../../hooks/useUserDocument';
import { Preferences } from './Preferences';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import NotFound from '../NotFound/NotFound';
import { Profile } from './Profile';
import { Account } from './Account';
import Spinner from '../utils/Spinner/Spinner';
import { NavLink } from '@mantine/core';
import {
  IconUserCircle,
  IconSettings,
  IconAdjustmentsHorizontal,
  IconShieldLock,
  IconHelp,
} from '@tabler/icons-react';
import { styled } from 'styled-components';
import { AdminSettings } from './AdminSettings';
import { AuthenticatedUser } from '../../context/AuthContext';
import { Help } from './Help';

type SettingsProps = {
  user: AuthenticatedUser;
};

const NavMenu = styled.div`
  min-width: 18rem;
  padding-right: 3rem;
  flex-shrink: 0;
  @media (max-width: 840px) {
    width: 100%;
    padding: 0 0 2rem 0;
    min-width: auto;
  }
`;

const SettingsPage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const SettingsContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Settings = ({ user }: SettingsProps) => {
  const { data: userDocument } = useUserDocumentWithId(user.uid);
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>Settings · OptomRx</title>
        <meta
          name="description"
          content="Adjust your basic profile details, verify your email, or delete your account."
        />
        <link rel="canonical" href="/settings" />
      </Helmet>
      <ContentContainer>
        <PageHeader title="Settings" description="Adjust your settings" />
        <SettingsPage>
          <NavMenu>
            <NavLink
              component={Link}
              to="/settings/profile"
              label="Profile"
              leftSection={<IconUserCircle size="1rem" stroke={1.5} />}
              active={pathname.includes('profile') || pathname == '/settings'}
              color="paleBlue"
            />
            <NavLink
              component={Link}
              to="/settings/account"
              label="Account"
              leftSection={<IconSettings size="1rem" stroke={1.5} />}
              active={pathname.includes('account')}
              color="paleBlue"
            />
            <NavLink
              component={Link}
              to="/settings/preferences"
              label="Preferences"
              leftSection={<IconAdjustmentsHorizontal size="1rem" stroke={1.5} />}
              active={pathname.includes('preferences')}
              color="paleBlue"
            />
            {user.isAdmin ? (
              <NavLink
                component={Link}
                to="/settings/admin"
                label="Admin"
                leftSection={<IconShieldLock size="1rem" stroke={1.5} />}
                active={pathname.includes('admin')}
                color="paleBlue"
              />
            ) : null}
            <NavLink
              component={Link}
              to="/settings/help"
              label="Help"
              leftSection={<IconHelp size="1rem" stroke={1.5} />}
              active={pathname.includes('help')}
              color="paleBlue"
            />
          </NavMenu>

          <SettingsContentContainer>
            {userDocument ? (
              <Routes>
                <Route path="/" element={<Profile userDocument={userDocument} user={user} />} />
                {/* Include prescriber details here */}
                <Route
                  path="/profile"
                  element={<Profile userDocument={userDocument} user={user} />}
                />
                {/* Include danger zone in account, along with change email and password */}
                <Route path="/account" element={<Account user={user} />} />
                {/* Include all app preferences, e.g. are restricted benefits expanded by default */}
                <Route path="/preferences" element={<Preferences userDocument={userDocument} />} />
                {/* Admin-only settings */}
                {user.isAdmin ? (
                  <Route path="/admin" element={<AdminSettings />} />
                ) : (
                  <Route path="/" element={<Profile userDocument={userDocument} user={user} />} />
                )}
                {/* TODO: Add '/billing' route */}
                <Route path="/help" element={<Help />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            ) : (
              <Spinner />
            )}
          </SettingsContentContainer>
        </SettingsPage>
      </ContentContainer>
    </>
  );
};

export default Settings;
