import Indications from '../Indications/Indications';
import DrugAutocomplete from '../DrugAutocomplete/DrugAutocomplete';
import { AlertState, SetAlertFunc } from '../utils/Alert/Alert';
import { PrescriptionFormValues } from '../../types/firestore';
import { Checkbox } from '@mantine/core';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import { UseFormReturnType } from '@mantine/form';
import classes from '../RxForm/RxForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';

type MedicationDetailsProps = {
  alerts: AlertState;
  setAlerts: SetAlertFunc;
  fetchDrug: (itemCode: string) => Promise<void>;
  form: UseFormReturnType<PrescriptionFormValues>;
  isIndicationsDefaultExpanded: boolean;
};

// Mostly created to help improve readability and size of parent RxForm component.
const MedicationDetails = ({
  alerts,
  setAlerts,
  fetchDrug,
  form,
  isIndicationsDefaultExpanded,
}: MedicationDetailsProps) => {
  const { indications, pbsRx, verified } = form.values.drugData;

  return (
    <div>
      {/* There must be enough info to identify the medicine, including form and strength, and 
      information regarding dosage, quantity, and repeats */}
      <DrugAutocomplete alerts={alerts} setAlerts={setAlerts} fetchDrug={fetchDrug} form={form} />

      <TextInputWithCheckmark
        id="dosage"
        classNames={{ root: classes.formInput }}
        label="Dosage directions"
        {...form.getInputProps('drugData.dosage')}
        description={alerts.dosage?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.dosage')}
      />

      <Checkbox
        classNames={{ root: classes.formInput }}
        label="PBS prescription"
        onKeyDown={handleCheckboxEnterPress}
        {...form.getInputProps('drugData.pbsRx', { type: 'checkbox' })}
        description={alerts.pbsRx?.message}
      />

      {verified && indications.length > 0 && pbsRx && (
        <Indications
          indicationsData={indications}
          isDefaultExpanded={isIndicationsDefaultExpanded}
        />
      )}

      <TextInputWithCheckmark
        classNames={{
          root: `${classes.formInput} ${classes.quantityInput}`,
          error: classes.quantityError,
          description: classes.quantityDescription,
        }}
        type="number"
        label="Quantity"
        {...form.getInputProps('drugData.quantity')}
        description={alerts.maxQuantity?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.quantity')}
      />

      <TextInputWithCheckmark
        classNames={{
          root: `${classes.formInput} ${classes.repeatsInput}`,
          error: classes.quantityError,
          description: classes.repeatsDescription,
        }}
        type="number"
        label="Repeats"
        {...form.getInputProps('drugData.repeats')}
        description={alerts.maxRepeats?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.repeats')}
      />
    </div>
  );
};

export default MedicationDetails;
