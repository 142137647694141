import { StyledFavourites } from './Favourites.styled';
import Spinner from '../utils/Spinner/Spinner';
import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Link } from 'react-router-dom';
import { useFormatting } from '../../hooks/useFormatting';
import type { User } from 'firebase/auth';
import { Prescription, userDocumentSchema } from '../../types/firestore';
import { useDocument } from '../../hooks/useDocument';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import toast from 'react-hot-toast';
import { Button } from '@mantine/core';
import { logError } from '../../utils/logError';
import { DeleteFavouriteModal } from './DeleteFavouriteModal';

type FavouritesProps = {
  user: User;
};

const Favourites = ({ user }: FavouritesProps) => {
  const { data, isLoading, error } = useDocument(`users/${user.uid}`, userDocumentSchema, {
    includeDocumentIds: false,
    // The user document is often not yet fully created on initial render(s)
    errorOnMissingDocument: false,
  });
  const { formatDrug } = useFormatting();

  // Initialised here to avoid reference errors in the modal when pulling selected script data
  const [selectedScript, setSelectedScript] = useState<Prescription | null>(null);

  useHandleFetchError(error);

  // Remove favourite from firestore database
  const deleteFavourite = async (scriptToDelete: Prescription, allFavourites: Prescription[]) => {
    const docRef = doc(db, 'users', user.uid);
    const updatedFavourites = allFavourites.filter(
      (fav) => fav.scriptID !== scriptToDelete.scriptID,
    );

    try {
      await updateDoc(docRef, {
        favourites: updatedFavourites,
      });
      setSelectedScript(null);
      toast.success('Deleted favourite');
    } catch (err) {
      logError(err);
      setSelectedScript(null);
      toast.error('An error occurred while deleting the favourite');
    }
  };
  if (isLoading) {
    return (
      <StyledFavourites>
        <Spinner />
      </StyledFavourites>
    );
  }

  // Handle null data separately from an error state
  if (data === null) {
    return (
      <StyledFavourites>
        <h2 className="Favourites__title">Favourites</h2>
        <ul className="fav-list fav-list--none">
          <li className="fav-item fav-item--none">
            Favourites can be added by clicking on an individual script in the &apos;Scripts&apos;
            page
          </li>
        </ul>
      </StyledFavourites>
    );
  }

  if (error) {
    return (
      <StyledFavourites>
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">No favourites added yet</li>
        </ul>
      </StyledFavourites>
    );
  }

  return (
    <StyledFavourites>
      <h2 className="Favourites__title">Favourites</h2>
      {data.favourites.length > 0 ? (
        <>
          <ul className="fav-list">
            <li className="first-list-item">
              <span>Script name</span>
              <span className="actions-span">Actions</span>
            </li>
            {data.favourites.map((fav) => (
              <li key={fav.scriptID} className="fav-item">
                <div className="item-name">
                  <span className="cell-title">Script name</span>
                  <span className="item-content">
                    {fav.customName === '' ? formatDrug(fav) : fav.customName}
                  </span>
                </div>
                <div className="actions">
                  <span className="cell-title">Actions</span>
                  <div className="btns">
                    <Button
                      component={Link}
                      className="prescribe"
                      variant="primary"
                      to="/prescription/new"
                      size="xs"
                      state={{
                        newRx: true,
                        rePrescribe: true,
                        scriptData: fav,
                      }}
                    >
                      Prescribe
                    </Button>
                    <Button
                      variant="transparent"
                      size="xs"
                      onClick={() => {
                        setSelectedScript(fav);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">
            Favourites can be added by clicking on an individual script in the &apos;Scripts&apos;
            page
          </li>
        </ul>
      )}
      {selectedScript ? (
        // FIXME: This implemenation means the modal won't fade in properly. It needs to be rendered
        // all the time, then have its visibility toggled, to allow for the fade in effect.
        // To much hassle to refactor for now.
        <DeleteFavouriteModal
          isOpen
          onClose={() => setSelectedScript(null)}
          onDelete={() => deleteFavourite(selectedScript, data.favourites)}
          favourite={selectedScript}
        />
      ) : null}
    </StyledFavourites>
  );
};

export default Favourites;
