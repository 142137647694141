import { ReactNode, createContext, useEffect, useReducer } from 'react';
import { auth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import type { User } from 'firebase/auth';
import { enrichUser } from '../utils/enrichUser';
import { logError } from '../utils/logError';

export type AuthenticatedUser = User & {
  isAdmin: boolean;
};

interface AuthState {
  user: AuthenticatedUser | null;
  authIsReady: boolean;
}

type AuthAction =
  | { type: 'LOGIN'; payload: AuthenticatedUser }
  | { type: 'LOGOUT' }
  | { type: 'AUTH_IS_READY'; payload: AuthenticatedUser | null };

// Exports the context object itself. This context object is retrieved with the useContext react hook, and allows use to destructure certain state props (such as user)
export const AuthContext = createContext<{
  user: AuthenticatedUser | null;
  authIsReady: boolean;
  dispatch: React.Dispatch<AuthAction>;
} | null>(null);

// A reducer function for handling different modifications to the same state
export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload };

    case 'LOGOUT':
      return { ...state, user: null };

    // Ran only once on initial render. Allows the auth status to be determined prior to rendering certain routes
    case 'AUTH_IS_READY':
      return { ...state, user: action.payload, authIsReady: true };

    default:
      return state;
  }
};

// ContextProvider component that wraps the application and affords access to global auth state and functions
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const enrichedUser = await enrichUser(user);
          dispatch({ type: 'AUTH_IS_READY', payload: enrichedUser });
        } else {
          dispatch({ type: 'AUTH_IS_READY', payload: user });
        }
      } catch (error) {
        logError(error);
      }
    });

    // Return the unsubscribe function to be called on unmount
    return unsub;
  }, []);

  return (
    <AuthContext.Provider value={{ user: state.user, authIsReady: state.authIsReady, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
