import { db } from '../../../firebase/config';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';
import { LegacyPrescriberDocument, UserDocument } from '../../../types/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { Button, Code, Table } from '@mantine/core';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function UsersWithEmptyFields() {
  const [isLoading, setIsLoading] = useState(false);
  const [usersWithEmptyProperties, setUsersWithEmptyProperties] = useState<
    {
      userId: string;
      numPrescribers: number;
    }[]
  >([]);

  /**
   * Show users with empty full name and prescriber number properties.
   */
  async function showUsersWithEmptyProperties() {
    const usersWithEmptyProperties: {
      userId: string;
      numPrescribers: number;
    }[] = [];

    try {
      setIsLoading(true);

      const usersCollection = collection(db, 'users');
      const userQuerySnapshot = await getDocs(usersCollection);

      const prescriberCollection = collection(db, 'prescribers');
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      // Map prescribers to an array for easier searching and filtering
      const allPrescribers = prescriberQuerySnapshot.docs.map(
        (doc) => doc.data() as LegacyPrescriberDocument,
      );

      userQuerySnapshot.forEach((doc) => {
        const userData = doc.data() as UserDocument;
        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        // Check if user has no full name or prescriber number. These are the critical properties,
        // since it's OK if the user has no qualifications.
        if (userData.fullName === '' || userData.prescriberNumber === '') {
          usersWithEmptyProperties.push({
            userId: doc.id,
            numPrescribers: relatedPrescribers.length,
          });
        }
      });
      setUsersWithEmptyProperties(usersWithEmptyProperties);
      setIsLoading(false);
      if (usersWithEmptyProperties.length === 0) {
        toast.success('No users with empty fields found.');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error scanning users collection:', error);
    }
  }

  const userRows = usersWithEmptyProperties.map((user) => (
    <Table.Tr key={user.userId}>
      <Table.Td>{user.userId}</Table.Td>
      <Table.Td>{user.numPrescribers}</Table.Td>
    </Table.Tr>
  ));

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Users with empty fields</h4>
      <p>
        Click this button to view all users who have empty values for their <Code>fullName</Code>{' '}
        and <Code>prescriberNumber</Code>properties in their user document.
      </p>
      <p>
        This will also show users who have no prescribers linked to their account, or have multiple
        prescribers.
      </p>
      <div className={migrationClasses.buttonsContainer}>
        <Button
          onClick={showUsersWithEmptyProperties}
          loading={isLoading}
          className={migrationClasses.button}
        >
          Show users with empty fields
        </Button>
        <Button
          onClick={() => setUsersWithEmptyProperties([])}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>
      {usersWithEmptyProperties.length > 0 && (
        <Table className={migrationClasses.table} striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>User ID</Table.Th>
              <Table.Th>Prescriber count</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{userRows}</Table.Tbody>
        </Table>
      )}
    </section>
  );
}
