import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToHash = (hashUrlFragment: string, behavior: ScrollBehavior = 'smooth') => {
  const id = hashUrlFragment.replace('#', '');
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior });
  }
};

const useHashScroll = (): void => {
  const location = useLocation();

  useEffect(() => {
    scrollToHash(location.hash);
  }, [location.hash]);
};

export default useHashScroll;
