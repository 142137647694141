import { styled } from 'styled-components';

const StyledPracticeForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  .fields {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .checkbox {
    margin: 0.75rem 0 1.25rem 0;
  }

  .PracticeForm__btns {
    width: 100%;
    padding-top: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;

    .submit {
      margin-right: 1.5rem;
    }
  }

  /* Landscape phones/portrait tables */
  @media (max-width: 590px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Portrait phones */
  @media (max-width: 450px) {
    .PracticeForm__btns {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        width: 90%;
        margin: 0;
      }

      .submit {
        margin-bottom: 1.5rem;
        margin-right: 0;
      }
    }
  }
`;

export { StyledPracticeForm };
