import { Button } from '@mantine/core';
import Subheading from '../utils/Subheading/Subheading';
import classes from './ErrorHandlingSection.module.css';
import { logError } from '../../utils/logError';
import { z } from 'zod';
import toast from 'react-hot-toast';
import { useState } from 'react';

function handleTriggerUnhandledError() {
  toast.success('Error triggered');
  setTimeout(() => {
    throw new Error('This error has been thrown RAW');
  }, 0);
}

function handleTriggerHandledError() {
  try {
    throw new Error('This error has been successfully caught');
  } catch (error) {
    logError(error);
    toast.success('Error triggered');
  }
}

function handleTriggerZodError() {
  const schema = z.number();
  try {
    schema.parse('not a number');
  } catch (error) {
    logError(error);
    toast.success('Error triggered');
  }
}

function handleTriggerLifecycleError(
  stateSetterFn: React.Dispatch<React.SetStateAction<undefined>>,
) {
  toast.success('Error triggered');
  stateSetterFn(() => {
    throw new Error('Error during React lifecycle');
  });
}

/**
 * A component for testing error handling under a variety of scenarios.
 *
 * Intended for use by admins in the 'Admin' section of OptomRx.
 */
export function ErrorHandlingSection() {
  // Arbitrary state to help throw errors during the rendering lifecycle. State itself isn't used
  const [_state, setState] = useState();
  return (
    <section className={classes.section}>
      <Subheading text="Error handling" />
      <p>
        This section allows admins to test error logging and handling in development and production
        environments,for both handled an unhandled errors.
      </p>
      <ul>
        <li>
          In development, the error should be logged to the console, with no Bugsnag notification.
        </li>
        <li>
          In production, the error should be reported on Bugsnag, but not logged to the console.
        </li>
      </ul>
      <div className={classes.errorButtonsContainer}>
        <Button variant="danger" onClick={handleTriggerUnhandledError}>
          Trigger unhandled error
        </Button>
        <Button color="violet" onClick={handleTriggerHandledError}>
          Trigger handled error
        </Button>
        <Button color="indigo" onClick={handleTriggerZodError}>
          Trigger handled Zod error
        </Button>
        <Button color="dark" onClick={() => handleTriggerLifecycleError(setState)}>
          Trigger rendering error
        </Button>
      </div>
    </section>
  );
}
