import { Button, Modal, Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from './DeletePracticeModal.module.css';
import baseModalClasses from '../../styles/Modal.module.css';

type DeletePracticeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  practice: {
    name: string;
    location: string;
  };
};

/**
 * Modal to confirm deletion of a practice.
 */
const DeletePracticeModal = ({ isOpen, onClose, onDelete, practice }: DeletePracticeModalProps) => {
  const { name, location } = practice;
  return (
    <Modal title="Delete practice" onClose={onClose} opened={isOpen}>
      <Alert variant="light" icon={<IconInfoCircle />} color="red" title="Attention">
        This will permanently delete the following practice details
      </Alert>
      <div className={classes.selectedPracticeContainer}>
        <div className={classes.selectedPracticeLabel}>Selected practice</div>
        <div>{`${name} (${location})`}</div>
      </div>
      <div className={baseModalClasses.buttons}>
        <Button className="cancel" variant="grey" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeletePracticeModal;
