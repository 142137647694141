import { StyledDashboard } from './Dashboard.styled';
import { Link } from 'react-router-dom';
import Favourites from '../Favourites/Favourites';
import { useEffect } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import type { User } from 'firebase/auth';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import { Button } from '@mantine/core';
import { VerifyEmailModal } from './VerifyEmailModal';
import { useDisclosure } from '@mantine/hooks';

type DashboardProps = {
  user: User;
  setFirstSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  firstSignIn: boolean;
};

const Dashboard = ({ user, firstSignIn, setFirstSignIn }: DashboardProps) => {
  const [isVerifyModalVisible, { open: openVerifyModal, close: closeVerifyModal }] =
    useDisclosure(false);

  useEffect(() => {
    // If the user has just signed up for an account and hits the home page for the first time, display the email modal
    if (firstSignIn) {
      openVerifyModal();
      setFirstSignIn(false);
      // Not relevant to display error or success to user, they will be able to manually resend if no email is received. The manual resend has success/error UI handlers. Hence no .then or .catch is included
      sendEmailVerification(user);
    }
  }, [firstSignIn, setFirstSignIn, user, openVerifyModal]);

  return (
    <>
      <Helmet>
        <title>Dashboard · OptomRx</title>
        <meta
          name="description"
          content="Choose a favourite script to quickly write a prescription, or start fresh with a blank prescription."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledDashboard className="Dashboard">
          <PageHeader
            title={`Welcome${user.displayName ? `, ${user.displayName}` : ''}`}
            description="Create a new script or prescribe one of your favourites"
          />

          <Button
            component={Link}
            to="/prescription/new"
            state={{ newRx: true }}
            variant="primary"
            size="md"
          >
            New prescription
          </Button>

          <div className="Favourites">
            <Favourites user={user} />
          </div>
          <VerifyEmailModal isOpen={isVerifyModalVisible} onClose={closeVerifyModal} />
        </StyledDashboard>
      </ContentContainer>
    </>
  );
};

export default Dashboard;
