import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { Button, Table } from '@mantine/core';
import { useState } from 'react';
import { db } from '../../../firebase/config';
import {
  LegacyPrescriberDocument,
  PrescriptionDocument,
  UserDocument,
} from '../../../types/firestore';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';
import { differenceInDays, formatDate } from 'date-fns';
import { deleteAccount } from '../../Settings/DeleteAccountModal';

type UserInfo = {
  id: string;
  fullNames: string[];
  scripts: Omit<PrescriptionDocument, 'favourite'>[];
  fraudulentWarningDate?: string;
  isReadyToDelete: boolean;
};

/**
 * Component to manage suspicious users, including:
 * - Showing users with suspicious script activity or prescriber names
 * - Marking users as notified of suspicious activity
 * - Indicating whether a user is ready for deletion (note that you cannot delete via UI)
 */
export function ManageSuspiciousUsers() {
  const [isLoadingShowUsers, setIsLoadingShowUsers] = useState(false);
  const [userBeingMarked, setUserBeingMarked] = useState<string | null>(null);
  const [suspiciousUsers, setSuspiciousUsers] = useState<UserInfo[]>([]);
  const [userBeingDeleted, setUserBeingDeleted] = useState<string | null>(null);

  async function getSuspiciousUsers() {
    const suspiciousUsers: UserInfo[] = [];

    try {
      setIsLoadingShowUsers(true);

      // Reference to the 'users' and 'prescribers' collections
      const usersCollection = collection(db, 'users');
      const prescriberCollection = collection(db, 'prescribers');

      const userQuerySnapshot = await getDocs(usersCollection);
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      const allPrescribers = prescriberQuerySnapshot.docs.map(
        (doc) => doc.data() as LegacyPrescriberDocument,
      );

      userQuerySnapshot.forEach((doc) => {
        const userData = doc.data() as UserDocument;
        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        const hasSuspiciousScripts = userData.scripts.some(
          (script) =>
            script.activeIngredient.includes('zepam') ||
            script.activeIngredient.includes('done') ||
            script.dosage.includes('tablet'),
        );

        const hasSuspiciousName =
          relatedPrescribers.some(
            (prescriber) =>
              prescriber.fullName.includes('Dr') || prescriber.fullName.includes('DR'),
            // FIXME: This type is incorrect
            // @ts-expect-error Type is incorrect, needs updating
          ) || userData.displayName.toLowerCase().includes('dr');

        if (hasSuspiciousScripts || hasSuspiciousName) {
          const userInfo: UserInfo = {
            id: doc.id,
            fullNames: relatedPrescribers.map((prescriber) => prescriber.fullName),
            scripts: userData.scripts,
            isReadyToDelete: false,
          };
          if (userData.fraudulentWarningDate) {
            userInfo.fraudulentWarningDate = userData.fraudulentWarningDate;
            // The user is ready to delete if it has been at least 7 days since the warning date
            userInfo.isReadyToDelete =
              differenceInDays(new Date(), new Date(userData.fraudulentWarningDate)) >= 7;
          }
          suspiciousUsers.push(userInfo);
        }
      });

      setSuspiciousUsers(suspiciousUsers);
      setIsLoadingShowUsers(false);
    } catch (error) {
      setIsLoadingShowUsers(false);
      console.error('Error scanning users collection:', error);
    }
  }

  /**
   * Mark a user as notified of suspicious activity
   * @param userId The ID of the user to mark as notified
   */
  async function markAsNotified(userId: string) {
    try {
      setUserBeingMarked(userId);

      const docRef = doc(db, 'users', userId);

      await updateDoc(docRef, {
        fraudulentWarningDate: new Date().toISOString(),
      });
      await getSuspiciousUsers();
      setUserBeingMarked(null);
    } catch (error) {
      setUserBeingMarked(null);
      console.error('Error updating user:', error);
    }
  }

  async function deleteUser(userId: string) {
    setUserBeingDeleted(userId);
    await deleteAccount({ userId, shouldDeleteAuthUser: false });
    setUserBeingDeleted(null);
    await getSuspiciousUsers();
  }

  const userRows = suspiciousUsers.map((user) => (
    <Table.Tr key={user.id}>
      <Table.Td>{user.id}</Table.Td>
      <Table.Td>{user.fullNames.length > 0 ? user.fullNames.join(', ') : 'N/A'}</Table.Td>
      <Table.Td>
        {user.scripts.length > 0 ? (
          <>
            {user.scripts.map((script) => (
              <div key={script.scriptID}>
                {script.activeIngredient} - {script.dosage}
              </div>
            ))}
          </>
        ) : (
          'No scripts'
        )}
      </Table.Td>
      <Table.Td>
        {user.fraudulentWarningDate
          ? formatDate(user.fraudulentWarningDate, 'dd-MM-yyyy')
          : 'Not yet notified'}
      </Table.Td>
      <Table.Td>
        <Button
          onClick={() => markAsNotified(user.id)}
          disabled={user.fraudulentWarningDate !== undefined}
          loading={userBeingMarked === user.id}
          size="xs"
        >
          Mark as notified
        </Button>
      </Table.Td>
      <Table.Td>{user.isReadyToDelete ? '✅' : '❌'}</Table.Td>
      <Table.Td>
        <Button
          onClick={() => deleteUser(user.id)}
          disabled={!user.isReadyToDelete}
          loading={userBeingDeleted === user.id}
          variant="danger"
          size="xs"
        >
          Delete data
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Suspicious users</h4>
      <p>Click this button to view users with suspicious script activity or prescriber names.</p>
      <div className={migrationClasses.buttonsContainer}>
        <div>
          <Button
            onClick={getSuspiciousUsers}
            loading={isLoadingShowUsers}
            className={migrationClasses.button}
          >
            Show suspicious users
          </Button>
        </div>

        <Button
          onClick={() => setSuspiciousUsers([])}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>
      {suspiciousUsers.length > 0 && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>User ID</Table.Th>
              <Table.Th>Prescriber names</Table.Th>
              <Table.Th>Scripts</Table.Th>
              <Table.Th>Notified of suspicion on</Table.Th>
              <Table.Th>Notify</Table.Th>
              <Table.Th>Ready for deletion?</Table.Th>
              <Table.Th>Delete user data</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{userRows}</Table.Tbody>
        </Table>
      )}
    </section>
  );
}
