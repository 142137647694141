import { addDoc, collection, deleteDoc, getDocs } from 'firebase/firestore';
import { Button } from '@mantine/core';
import { useState } from 'react';
import { db } from '../../../firebase/config';
import {
  LegacyPrescriberDocument,
  PracticeData,
  PracticeDocument,
  UserDocument,
} from '../../../types/firestore';
import migrationClasses from './MigrationSection.module.css';
import toast from 'react-hot-toast';
import { filterUniquePrescribers } from './UsersWithMultiplePrescribers';

function convertPrescriberToPractice(prescriber: LegacyPrescriberDocument): PracticeDocument {
  return {
    streetAddress: prescriber.streetAddress,
    subpremise: prescriber.subpremise,
    suburb: prescriber.suburb,
    postcode: prescriber.postcode,
    state: prescriber.state,
    default: prescriber.default,
    phoneNumber: prescriber.phoneNumber,
    practiceName: prescriber.practiceName,
    prefix: prescriber.prefix,
    userId: prescriber.uid,
  };
}

export function MigratePrescribersToPractices() {
  const [isLoading, setIsLoading] = useState(false);

  async function migrate() {
    const conversions: Record<string, PracticeDocument> = {};
    const unmigratablePrescribers: LegacyPrescriberDocument[] = [];
    try {
      setIsLoading(true);

      const usersCollection = collection(db, 'users');
      const prescriberCollection = collection(db, 'prescribers');
      const testPracticeCollection = collection(db, 'practices');

      const userQuerySnapshot = await getDocs(usersCollection);
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      // Delete all existing practices to begin with
      console.log('Deleting all existing practices...');

      const practicesSnapshot = await getDocs(testPracticeCollection);
      const allPractices = practicesSnapshot.docs.map((doc) => doc.data() as PracticeData);
      console.log('Found existing practices:', allPractices);

      practicesSnapshot.docs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      console.log('Deleted all existing practices');
      console.log('Migrating prescribers to practices...');

      // Map prescribers to an array for easier searching and filtering
      const allPrescribers = prescriberQuerySnapshot.docs.map(
        (doc) => doc.data() as LegacyPrescriberDocument,
      );

      console.log('Found all prescribers:', allPrescribers);
      const unmatchedPrescribers = allPrescribers.filter((prescriber) => {
        return !allPractices.find((practice) => practice.userId === prescriber.uid);
      });
      console.log('Unmatched prescribers:', unmatchedPrescribers);

      for (const doc of userQuerySnapshot.docs) {
        const userData = doc.data() as UserDocument;
        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        // No action required
        if (relatedPrescribers.length == 0) {
          continue;
        }

        // Only need a 1:1 conversion from prescriber to practice
        if (relatedPrescribers.length == 1) {
          const practice = convertPrescriberToPractice(relatedPrescribers[0]);
          await addDoc(testPracticeCollection, practice);
          conversions[relatedPrescribers[0].practiceName] = practice;
          continue;
        }

        // No action required - we have to resolve these manually
        const uniquePrescribers = filterUniquePrescribers(relatedPrescribers);
        if (uniquePrescribers.length > 1) {
          unmigratablePrescribers.push(...uniquePrescribers);
          // console.log('Multiple prescriber numbers found for user:', userData);
          continue;
        }

        // These users have multiple prescribers with the same prescriber number, which can be
        // combined into a single prescriber safely. These two properties should exist.
        // If `fullName` does not, we should use `PopulateMissingUserFields` to add it.
        // If `prescriberNumber` does not, our code above is incorrect.
        const prescriberNumber = uniquePrescribers[0].prescriberNumber;
        const fullName = userData.fullName;

        if (!prescriberNumber) {
          console.error('No prescriber number found for user:', userData);
          continue;
        }

        if (!fullName) {
          console.error('No full name found for user:', userData);
          continue;
        }

        // Now, we need to create practice entities for each prescriber
        const practices: PracticeDocument[] = [];
        relatedPrescribers.forEach((prescriber) => {
          const practice: PracticeDocument = {
            streetAddress: prescriber.streetAddress,
            subpremise: prescriber.subpremise,
            suburb: prescriber.suburb,
            postcode: prescriber.postcode,
            state: prescriber.streetAddress,
            default: prescriber.default,
            phoneNumber: prescriber.phoneNumber,
            practiceName: prescriber.practiceName,
            prefix: prescriber.prefix,
            userId: prescriber.uid,
          };

          practices.push(practice);
        });

        for (const practice of practices) {
          // Create the practice in the database
          try {
            // Update data on backend
            await addDoc(testPracticeCollection, practice);
            conversions[practice.practiceName] = practice;
          } catch (error) {
            console.error('Error adding practice:', error);
          }
        }
      }
      console.log('All prescribers migrated to practices:', conversions);
      console.log(
        `Could not migrate ${unmigratablePrescribers.length} prescribers: `,
        unmigratablePrescribers,
      );

      const toInvestigate = unmatchedPrescribers.filter((prescriber) => {
        return !unmigratablePrescribers.includes(prescriber);
      });
      console.log('Prescribers to investigate:', toInvestigate);

      toast.success('Migrated prescribers to practices successfully');
    } catch (error) {
      console.error('Error scanning users collection:', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function checkPractices() {
    const practicesRef = collection(db, 'testPractices');
    const userRef = collection(db, 'users');

    const userDocs = await getDocs(userRef);
    const allUsers = userDocs.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    const querySnapshot = getDocs(practicesRef);
    querySnapshot.then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        const practiceData = doc.data() as PracticeData;
        if (!allUsers.find((user) => user.id === practiceData.userId)) {
          console.error('Practice has no linked user:', practiceData);
        } else {
          console.log('Practice has linked user:', practiceData.practiceName);
        }
      });
    });
  }

  return (
    <section>
      <Button onClick={migrate} loading={isLoading} className={migrationClasses.button} color="red">
        MIGRATE
      </Button>
      <div>
        <Button
          onClick={checkPractices}
          loading={isLoading}
          className={migrationClasses.button}
          color="orange"
        >
          Check linked practices
        </Button>
      </div>
    </section>
  );
}
