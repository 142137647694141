import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { Button, Table } from '@mantine/core';
import { useState } from 'react';
import { db } from '../../../firebase/config';
import { LegacyPrescriberDocument, UserDocument } from '../../../types/firestore';
import classes from '../Admin.module.css';
import migrationClasses from './MigrationSection.module.css';

type LegacyPrescriberWithDocId = LegacyPrescriberDocument & {
  id: string;
};

type UserInfo = {
  id: string;
  fullName?: string;
  /**
   * An object containing a prescriber number key, and an array of practices linked to that number.
   */
  practices: Record<string, LegacyPrescriberWithDocId[]>;
};

/**
 * Component to show users' practices. Note that, prior to migrating from prescribers
 * to practices, this more so represents prescriber locations, grouped by prescriber number.
 */
export function ShowUserPractices() {
  const [isLoading, setIsLoading] = useState(false);
  const [userPractices, setUserPractices] = useState<UserInfo[]>([]);
  const [userBeingDeleted, setUserBeingDeleted] = useState<string | null>(null);

  async function getUserPractices(options: { multipleOnly?: boolean }) {
    const userPractices: UserInfo[] = [];

    try {
      setIsLoading(true);

      // Reference to the 'users' and 'prescribers' collections
      const usersCollection = collection(db, 'users');
      const prescriberCollection = collection(db, 'prescribers');

      const userQuerySnapshot = await getDocs(usersCollection);
      const prescriberQuerySnapshot = await getDocs(prescriberCollection);

      const allPrescribers = prescriberQuerySnapshot.docs.map((doc) => {
        const data = doc.data() as LegacyPrescriberDocument;
        return {
          id: doc.id,
          ...data,
        };
      });

      userQuerySnapshot.forEach((doc) => {
        const userData = doc.data() as UserDocument;
        const relatedPrescribers = allPrescribers.filter((prescriber) => prescriber.uid === doc.id);

        // Group prescribers by prescriber number
        const prescribersByNumber: Record<string, LegacyPrescriberWithDocId[]> = {};
        relatedPrescribers.forEach((prescriber) => {
          if (!prescribersByNumber[prescriber.prescriberNumber]) {
            prescribersByNumber[prescriber.prescriberNumber] = [];
          }
          prescribersByNumber[prescriber.prescriberNumber].push(prescriber);
        });

        if (options.multipleOnly && Object.keys(prescribersByNumber).length < 2) {
          return;
        }

        userPractices.push({
          id: doc.id,
          fullName: userData.fullName,
          practices: prescribersByNumber,
        });
      });

      setUserPractices(userPractices);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error scanning users collection:', error);
    }
  }

  async function deletePrescriber(prescriberId: string, multipleOnly: boolean) {
    setUserBeingDeleted(prescriberId);
    const prescriberDoc = doc(db, 'prescribers', prescriberId);
    await deleteDoc(prescriberDoc);
    setUserBeingDeleted(null);
    await getUserPractices({
      multipleOnly,
    });
  }

  // Render the users in a Mantine table, with their practices grouped by prescriber number
  const userRows = userPractices.map((user) => (
    <Table.Tr key={user.id}>
      <Table.Td>{user.id}</Table.Td>
      <Table.Td>{user.fullName ?? 'No Full Name'}</Table.Td>
      <Table.Td>
        {Object.entries(user.practices).map(([prescriberNumber, practices], index) => (
          <div key={prescriberNumber}>
            <strong>Prescriber {prescriberNumber}:</strong>
            <ul>
              {practices.map((practice) => (
                <li key={`${practice.streetAddress}-${prescriberNumber}-${index}`}>
                  <span>
                    {practice.practiceName.trim().length > 0 ? `${practice.practiceName} - ` : ''}
                    {`${practice.subpremise} ${practice.streetAddress}`}
                  </span>
                  <ul>
                    <li>
                      {practice.id} - {practice.fullName}
                    </li>
                  </ul>
                  <Button
                    onClick={() => deletePrescriber(practice.id, true)}
                    loading={userBeingDeleted === user.id}
                    variant="danger"
                    size="xs"
                  >
                    Delete prescriber
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <section className={classes.section}>
      <h4 className={migrationClasses.subheader}>Users practices</h4>
      <p>
        Click this button to view a table of all users and their practices, grouped by prescriber
        number.
      </p>
      <div className={migrationClasses.buttonsContainer}>
        <div>
          <Button
            onClick={() =>
              getUserPractices({
                multipleOnly: true,
              })
            }
            loading={isLoading}
            className={migrationClasses.button}
          >
            Show user practices (multiple only)
          </Button>
          <Button
            onClick={() =>
              getUserPractices({
                multipleOnly: false,
              })
            }
            loading={isLoading}
            className={migrationClasses.button}
            style={{ marginLeft: '1rem' }}
          >
            Show user practices (all)
          </Button>
        </div>

        <Button
          onClick={() => setUserPractices([])}
          className={migrationClasses.button}
          variant="outline"
        >
          Hide results
        </Button>
      </div>
      {userPractices.length > 0 && (
        <Table className={migrationClasses.table} striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>User ID</Table.Th>
              <Table.Th>Full Name</Table.Th>
              <Table.Th>Practices</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{userRows}</Table.Tbody>
        </Table>
      )}
    </section>
  );
}
