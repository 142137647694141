import { updateProfile, type User } from 'firebase/auth';
import { UserDocument, userDocumentSchema } from '../types/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

const getUserDocumentOnce = async (userId: string): Promise<UserDocument> => {
  const docRef = doc(db, 'users', userId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw new Error('Corresponding user document does not exist');
  } else {
    const data = docSnap.data();
    const validatedData = userDocumentSchema.parse(data);
    return validatedData;
  }
};

/**
 * Called on user log in to enrich the Firebase user document with admin status.
 * FIXME: Temporarily included updating Firebase user display name here so it gradually migrates
 * all existing users to use full name for display name. May never remove it though.
 */
export const enrichUser = async (user: User) => {
  const userDocument = await getUserDocumentOnce(user.uid);
  await updateProfile(user, {
    displayName: userDocument.fullName,
  });
  return Object.assign(Object.create(Object.getPrototypeOf(user)), user, {
    isAdmin: userDocument.isAdmin ?? false,
  });
};
